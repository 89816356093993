tbody tr:nth-child(even) {
    background-color: var(--ROWBACK);
  }
  
  .tableContainer h1
    {
      margin-top: 48px;
    }

    .tableContainer h2 {
    margin-top: 48px;
  }

  .tableMain{
    padding: 16px 0px 16px 16px;
    background-color: white;
    border-radius: 10px;
   
  }
  .tableWrapper {
    padding: 0px 16px 0px 0px;
    color: var(--LIGHTPRIMARY);
    scrollbar-color: var(--PRIMARY) var(--OFFWHITE);
    scrollbar-width: 8px;
    overflow-y: auto;
  }

  .tableContainer {
    background: var(--WHITE);
    overflow-y: auto;
  }
 .tableContainer::-webkit-scrollbar {
    height: 6px;
    width:6px;
  } 
   .tableContainer::-webkit-scrollbar-track {
    background: var(--OFFWHITE);
    border:0px !important;
  }
  
  .tableContainer::-webkit-scrollbar-thumb {
    background: var(--PRIMARY);
    border-radius: 24px;
  }
  .tableContainer .loading-spinner{
    height: 100% !important;
    overflow-y: auto;
  }
  /* .tableContainer::-webkit-scrollbar {
    overflow: auto;
    width: 0;
    display: inherit;
  } */
  table {
    border-spacing: 0;
    width: 100%;
  }
  
  thead {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  
  tbody {
    font-size: 14px;
  }
  
  th {
    padding: 25px 12px;
    max-height: 64px;
  }
  td {
    padding: 17.5px 12px;
    /* word-break: break-all; */
    max-height: 64px;
    font-family: var(--Aeonik-TRIAL);
  }
  
  tr:last-child td {
    border-bottom: none;
  }
thead{
  position: relative;
}
  tr.sticky {
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 0px;
    z-index: 100;
    background: var(--OFFWHITE);
    font-weight: var(--FONT_WEIGHT_700);
    font-family:"Inter", sans-serif;;
    font-size: var(--FONT10);
    line-height: 14px;
    letter-spacing: 1.25px;
  }
  
  th.sticky,
  td.sticky {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    left: 0;
    background: var(--ROWBACK);
  }
  
  th.sticky::after,
  td.sticky::after {
    content: "";
    position: absolute;
    right: -6px;
    top: 0;
    bottom: -1px;
    width: 5px;
  }
  
  th.sticky::before,
  td.sticky::before {
    content: "";
    position: absolute;
    left: -6px;
    top: 0;
    bottom: -1px;
    width: 5px;
  }

  .subscriptionActive {
    padding: 4px 8px 4px 8px;
    color: var(--SUBGREEN);
    border-radius: 4px;
    width: fit-content;
    font-family: var(--Aeonik-TRIAL);
    font-weight: var(--FONT_WEIGHT_600);
    background-color: var(--ACTIVEBACK);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .subscriptionInActive {
    color: var(--LIGHTRED);
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    width: fit-content;
    font-family: var(--Aeonik-TRIAL);
    font-weight: var(--FONT_WEIGHT_400);
    background-color: var(--INACTIVEBACK);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .userContainer {
    background: var(--SUBTERTIARY);
    padding: 64px;
  }
  .userContainer .dropdown-option label{
    word-wrap: break-word;
  }

  .headerMain {
    padding-bottom: 24px;
    height: 151px;
    display: flex;
    justify-content: space-between;
  }
  .headerMain button {
    background-color: var(--WHITE);
    width: fit-content;
    color: var(--PRIMARYVARIANT);
    padding: 16px 24px 16px 24px;
    border-radius: 6px;
    border: 1px solid var(--PRIMARYVARIANT);
  }
  
 .userContainer .headerContainer{
    height: 163px;
  }
  
  .headerContainer h1 {
    font-family: var(--Aeonik-TRIAL);
    font-size: var(--FONT16);
    font-weight: var(--FONT_WEIGHT_400);
    line-height: 20px;
    letter-spacing: 1.25px;
    color: var(--PRIMARYVARIANT);
    margin-bottom: 16px;
  }
  
  .searchContainer button {
    background-color: var(--SECONDARY);
    border-radius: 6px;
    padding: 10px 16px 10px 16px;
    font-size: var(--FONT14);
    font-weight: var(--FONT_WEIGHT_800);
    color: var(--PRIMARYVARIANT);
    border: 1px solid var(--SECONDARY);
  }
  
  .searchMain {
    display: flex;
  }
  .tableHeader {
    display: flex;
    justify-content: space-between;
  }
  .searchContainer .filterBtn{
    background-color: var(--WHITE);
    width: fit-content;
    color: var(--PRIMARYVARIANT);
    padding: 16px 24px 16px 24px;
    border-radius: 6px;
    border: 1px solid var(--PRIMARYVARIANT);
    cursor: pointer;
    white-space: nowrap;
  }
.userContainer .columnContainer{
  word-break: break-word;
  min-width:164px
  
}
 .locationContainer {
  /* word-break: break-all; */
  word-wrap: break-word;
  max-width: 258px !important;
  min-width: 193px;
}
 .columnSubContainer{
  word-wrap: break-word;
  max-width: 258px !important;
  min-width: 193px;
 }
.authPages {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--PRIMARY);
  min-height: 100vh;
  padding: 40px 0;
}
.authCenter {
  width: 407px;
}
.whiteBack {
  position: relative;
  display: block;
  background-color: var(--PRIMARY);
  border: 1px solid var(--WHITE);
  padding: 36px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 12px 24px 0px rgba(17, 17, 17, 0.08);
} 

  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none !important;
  }
.headerTitle {
  color: var(--WHITE);
  font-weight: var(--FONT_WEIGHT_700);
  margin-bottom: 20px;
  letter-spacing: 1.25px;
}
.button-update{
  color: var(--SECONDARY) !important;
  font-weight: var(--FONT_WEIGHT_700) !important; 
  font-size: var(--FONT14) !important;

}

.emailText {
  color: var(--PRIMARY);
  font-size: var(--FONT13);
  margin-top: 2px;
}
.forgotPassCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  font-family:"Inter", sans-serif;
  height: 48px;
}
.forgotPasswordLink {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_700);
  color: var(--SECONDARY);
  text-decoration: none;
  letter-spacing: 1.25px;
}

.loginOptMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
}
hr {
  width: 35%;
  margin: 0 !important;
  color: var(--WHITE) !important;
  opacity: 19% !important;
}
.loginIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.copyRightTitle {
  margin: 48px 0 0 0;
  text-align: center;
}
.checkBoxMain  {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  font-size: 24px;
  color: var(--SECONDARY);
}
.checkBoxMain  .checkbox {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.checkboxLabel {
  font-size: var(--FONT10);
  font-weight: var(--FONT_WEIGHT_700);
  color: var(--WHITE);
  cursor: pointer;
  margin-left: 8px;
  letter-spacing: 1.25px;
}
.checkBoxMain  i {
  margin-right: 8px;
  font-size: var(--FONT16);
  color: var(--GRAY);
}
.checkBoxMain .icon-unchecked{
  font-size: var(--FONT16);
}
.checkBoxMain .icon-checked{
  font-size: var(--FONT16);
}
.form .otpContainer {
  justify-content: space-between;
}

.form .otpContainer .otp-input-none-style {
  height: 48px;
  width: 65px !important;
  padding: 12px 16px;
  border: 1px solid var(--PLACEHOLDER);
  border-radius: 8px;
  font-size: var(--FONT16);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: var(--FONT_WEIGHT_400);
  color: var(--PLACEHOLDER);
  background-color: var(--WHITE);
}

.form .otpContainer .otpInputFillStyle {
  height: 48px;
  width: 65px !important;
  padding: 12px 16px;
  border: 1px solid var(--PRIMARY);
  border-radius: 8px;
  font-size: var(--FONT16);
  font-weight: var(--FONT_WEIGHT_600);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 30px;
  color: var(--PRIMARY);
  background-color: var(--WHITE);
}

.form .otpContainer input:focus-visible {
  outline: none;
}

.authOptions {
  font-size: var(--FONT14);
  color: var(--WHITE);
  text-align: center;
  font-style: normal;
  font-family: "Inter", sans-serif;
  font-weight: var(--FONT_WEIGHT_700);
  line-height: 18px;
  letter-spacing: 1.25px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
}

.authStyle{
  color: var(--SECONDARY);
  cursor: pointer;
  text-align: center;
  margin-bottom: 0;
  text-decoration: none;
}
.resend-code-timer-style {
  color: var(--SECONDARY) !important;
  margin-bottom: 0px;
}
.checkBoxMain .checkboxLabel{
  font-size: var(--FONT10) !important;
  font-family: "Inter", sans-serif;
}

.termsLink a{
  text-decoration: none;
  color: var(--SECONDARY);
  font-size: var(--FONT10);
}


@import "../../assets/css/variables.css";

.dropzone{
  cursor: pointer;
/* flex: 1;
display: flex;
flex-direction: column;
align-items: center;
padding: 20px;
border-width: 2px;
border-radius: 2px;
border-color: #eeeeee;
border-style: dashed;
background-color: #fafafa;
color: #bdbdbd;
outline: none;
transition: border .24s ease-in-out; */
}
@-moz-document url-prefix() {
  .dropzone input {
    display: block !important;
    cursor: pointer;
    position: absolute;
    opacity: 0;
    height: 427px;
  }
}
.show-file-name li{
  margin-top: 7px;
  list-style: none;
  border: 1px solid;
  border-radius: 4px;
  padding: 8px;
  border-color: #E3E3E3;
}

.show-file-name  .icon-cross{
  font-size: 24px !important;
}

.error-file li{
  border: 1px solid;
  margin-top: 7px;
  list-style: none;
  border-radius: 4px;
  padding: 8px;
  border-color: var(--RED);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error-file li .icon-cross {
  background-color: var(--RED);
  border-radius: 50%;
  color: var(--WHITE);
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-text{
  color: var(--RED);
  font-weight: var(--FONT_WEIGHT_400);
  font-size: var(--FONT10);
}

.Upload-text{
  color: var(--GREYPRIMARY);
}

 /* //success section css start */ 
.success-section{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 600px;
}

.success-section button{
  background-color: var(--SECONDARY);
  color: var(--PRIMARY);
  text-transform: uppercase;
  padding: 16px 24px;
  border: none;
  border-radius: 8px;
  font-weight: var(--FONT_WEIGHT_800);
  font-size: var(--FONT22);
  margin-top: 38px;
}
.success-section .success-text{
  color: var(--BLACK);
  font-size: var(--FONT28);
  font-weight: var(--FONT_WEIGHT_800);
  margin-bottom: 5px;
}

.success-section .document-text{
  color: var(--GREYPRIMARY);
  font-size: var(--FONT18);
  margin-bottom: 0;
}

/* --------- error section----------  */
.error-section{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 600px;
}
.error-section .success-text{
  color: var(--RED);
  font-size: var(--FONT28);
  font-weight: var(--FONT_WEIGHT_800);
  margin-bottom: 5px;
}
.error-section button{
  background-color: var(--SECONDARY);
  color: var(--PRIMARY);
  text-transform: uppercase;
  padding: 16px 24px;
  border: none;
  border-radius: 8px;
  font-weight: var(--FONT_WEIGHT_800);
  font-size: var(--FONT22);
  margin-top: 38px;
}
.error-section .document-text{
  color: var(--GREYPRIMARY);
  font-size: var(--FONT18);
  margin-bottom: 0;
}

.animation-component{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 650px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #C0D8F6;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@font-face {
  font-family: 'triptix';
  src:  url('./../fonts/triptix.eot?i69spa');
  src:  url('./../fonts/triptix.eot?i69spa#iefix') format('embedded-opentype'),
        url('./../fonts/triptix.ttf?i69spa') format('truetype'),
        url('./../fonts/triptix.woff?i69spa') format('woff'),
        url('./../fonts/triptix.svg?i69spa#triptix') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-variant:normal;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'triptix' !important;
  speak: never;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-menu:before {
  content: "\e922";
}
.icon-close:before {
  content: "\e921";
}
.icon-arrow-left:before {
  content: "\e920";
}
.icon-link:before {
  content: "\e91f";
}
.icon-Travel:before {
  content: "\e91e";
}
.icon-ticket:before {
  content: "\e91d";
}
.icon-refresh:before {
  content: "\e91c";
}
.icon-error:before {
  content: "\e91b";
}
.icon-doneAll:before {
  content: "\e91a";
}
.icon-cross:before {
  content: "\e919";
}
.icon-x-circle:before {
  content: "\e918";
}
.icon-check-circle:before {
  content: "\e917";
}
.icon-box-arrow-up:before {
  content: "\e916";
}
.icon-arrow:before {
  content: "\e915";
}
.icon-camera:before {
  content: "\e913";
}
.icon-downArrow:before {
  content: "\e914";
}
.icon-dropdown:before {
  content: "\e910";
}
.icon-filter:before {
  content: "\e911";
}

.icon-hide:before {
  content: "\e900";
}
.icon-show:before {
  content: "\e901";
}

.icon-mail:before {
  content: "\e902";
}

.icon-checkbox:before {
  content: "\e903";
}

.icon-checkmark:before {
  content: "\e904";
}
.icon-info:before {
  content: "\e905";
}
.icon-calendar:before {
  content: "\e906";
}
.icon-football:before {
  content: "\e909";
}
.icon-home:before {
  content: "\e90d";
}
.icon-aeroplane:before {
  content: "\e908";
}
.icon-user:before {
  content: "\e90c";
}
.icon-upload:before {
  content: "\e90b";
}
.icon-settings:before {
  content: "\e907";
}
.icon-logOut:before {
  content: "\e90a";
}
.icon-checked:before {
  content: "\e90e";
}
.icon-unchecked:before {
  content: "\e90f";
}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 19, 2024 */

@font-face {
  font-family: 'Faktum Test';
  src: url('./../fonts/FaktumTest-Regular.woff2') format('woff2'),
      url('./../fonts/FaktumTest-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Faktum Test';
  src: url('./../fonts/FaktumTest-Bold.woff2') format('woff2'),
      url('./../fonts/FaktumTest-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik TRIAL';
  src: url('./../fonts/AeonikTRIAL-Regular.woff2') format('woff2'),
      url('./../fonts/AeonikTRIAL-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik TRIAL';
  src: url('./../fonts/AeonikTRIAL-Light.woff2') format('woff2'),
      url('./../fonts/AeonikTRIAL-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik TRIAL';
  src: url('./../fonts/AeonikTRIAL-Bold.woff2') format('woff2'),
      url('./../fonts/AeonikTRIAL-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
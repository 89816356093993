:root {
  --PRIMARY: #081b31;
  --SECONDARY: #dcfd43;
  --BLUE: #1877f2;
  --BLUEBACK: #4285f4;
  --PLACEHOLDER_TXT: #bbbbbb;
  --RED: #ff5a51;
  --WHITE: #ffffff;
  --GREEN:#79CA5B;
  --OFFWHITE: #fafafa;
  --ROWBACK:#F8F9FA;
  --GRAY: #585858;
  --PLACEHOLDER: #ADB5BD;
  --SUBGRAY:#DEE2E6;
  --LIGHTGRAY: #5c5c5c;
  --SUBBLUE: #3d7cec;
  --SUBGREEN :  #70E78B;
  --GREENVARIANT:#11EB85;
  --SUBLIGHTBLUE : #6CDFEF;
  --SUBORANGE: #EC7F50;
  --SUBPURPLE : #B672DF;
  --SUBRED:#FF003F;
  --LIGHTRED:#E83D4A;
  --SUBTERTIARY:#DDDBD2;
  --BTNDISABLE:#D7DFE9;
  --ACTIVEBACK:#d2f9db;
  --INACTIVEBACK:#f7bbc0;
  --LIGHTPRIMARY:#4C596C;
  --PRIMARYVARIANT:#081B31;
  --BLACK: #000000;
  --ICONBLUE:#007AFF;
  --GREY:#DFDFDF;
  --DARKPRIMARY :#001C33;
  --SUBPRIMARY:#1F2F47;
  --GRAYVARIANT: #DEE2E6;
  --BOXBACK:#11111114;
  --REDVARIANT:#E41D1D;
  --GREYPRIMARY :#676767;
  --BORDER:#A4A4A4;
  --GRAYBACK:#EDEEF1;
  --YELLOWVARIANT:#DCFD43;
  --GREYVARIANT:#B5BEC6;
  --BORDERGRAY:#E4E5E7;

  /*FONTS*/
  --FONT2:2px;
  --FONT3:3px;
  --FONT4:4px;
  --FONT5:5px;
  --FONT6:6px;
  --FONT7:7px;
  --FONT8:8px;
  --FONT9:9px;
  --FONT10: 10px;
  --FONT11: 11px;
  --FONT12: 12px;
  --FONT13: 13px;
  --FONT14: 14px;
  --FONT15: 15px;
  --FONT16: 16px;
  --FONT17: 17px;
  --FONT18: 18px;
  --FONT20: 20px;
  --FONT22: 22px;
  --FONT24: 24px;
  --FONT26: 26px;
  --FONT28: 28px;
  --FONT32: 32px;
  --FONT34: 34px;
  --FONT36: 36px;
  --FONT38: 38px;
  --FONT40: 40px;
  --FONT48: 48px;
  --FONT230: 230px;

  /*Font Weight*/
  --FONT_WEIGHT_300: 300;
  --FONT_WEIGHT_400: 400;
  --FONT_WEIGHT_500: 500;
  --FONT_WEIGHT_600: 600;
  --FONT_WEIGHT_700: 700;
  --FONT_WEIGHT_800: 800;

/* font family */
--Faktum-Test:'Faktum Test';
--Aeonik-TRIAL:'Aeonik TRIAL';
}

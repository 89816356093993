.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
  }
  
  .switch input {
    display: none;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--BTNDISABLE);
    transition: .4s;
    border-radius: 20px;
}

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    top: 2px;
    left: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 20px;
  }
  
  input:checked + .slider {
    background-color: var(--SUBGREEN);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--SUBGREEN);
  }
  
  input:checked + .slider:before {
    transform: translateX(20px);
  }
  
@import "./variables.css";
@import "./icons.css";
@import "./table.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

body {
  margin: 0;
  padding: 0;
  background-color: var(--PRIMARY);
  font-size: normal;
  font-weight: normal;
  font-family: var(--Faktum-Test);
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-variant: normal;
  scrollbar-width: none;
}

.toast {
  position: fixed;
  width: 100%;
  display: flex !important;
  align-items: center;
  z-index: 99;
  justify-content: center;
  background: transparent;
  box-shadow: none;
  border: 0;
  font-size: inherit;
  border-radius: 0;
  color: inherit;
  margin-top: -100px;
  z-index: 250;
}

.toast.show {
  margin-top: 30px;
}
.eventContent{
  text-wrap: nowrap;
}
.toastCenter {
  text-align: center;
  padding: 24px 20px;
  border-radius: 16px;
  box-shadow: 0px 32px 64px 0px var(--BOXBACK);
}

.toastCenter span {
  font-size: var(--FONT24);
}

.toastCenter .txtcontain {
  font-family: Arial, Helvetica, sans-serif;
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_400);
  color: var(--SUBPRIMARY);
  margin-top: 16px;
}

.toastCenter .txtcontain strong {
  font-weight: var(--FONT_WEIGHT_500);
}

.toastCenter .txtcontain strong {
  font-size: var(--FONT16);
}

/* Toast success */
.success .toastCenter {
  background-color: var(--WHITE);
  max-width: 275px;
  min-width: 275px;
}

.success .toastCenter span {
  color: var(--GREEN);
}

.success .toastCenter .txtcontain {
  color: var(--SUBPRIMARY);
}

/* Toast error */
.error .toastCenter {
  background-color: var(--WHITE);
  max-width: 275px;
  min-width: 275px;
}

.error .toastCenter span,
.error .toastCenter .txtcontain {
  color: var(--RED);
}

/* Toast info */
.info .toastCenter {
  background-color: var(--WHITE);
  max-width: 275px;
  min-width: 275px;
}

.info .toastCenter span {
  color: var(--RED);
}

.info .toastCenter .txtcontain {
  color: var(--SUBPRIMARY);
}

.authHeaderBack {
  margin-bottom: 60px;
  position: relative;
  background-color: var(--AUTHBACK);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 18px;
}

.authHeaderBack h1 {
  margin: 0;
  font-size: var(--FONT18);
  font-weight: var(--FONT_WEIGHT_600);
  color: var(--PRIMARY);
}
.form .otpContainer .otp-input-none-style::placeholder {
 color: var(--PLACEHOLDER_TXT);
 font-family: Arial, Helvetica, sans-serif;
 font-size: var(--FONT16);
 font-weight: var(--FONT_WEIGHT_400);
}
.authHeaderBack p {
  margin: 0;
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_400);
  color: var(--PRIMARY);
}

.headerDescription {
  display: block;
  padding: 20px 38px 0 38px;
  margin: 0;
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_400);
  color: var(--GRAY);
}
.profileContainer #react-select-2-listbox{
  max-height:160px;
  overflow: auto;
  font-family: Arial, Helvetica, sans-serif;
  scrollbar-color: var(--PRIMARY) transparent;
  scrollbar-width: thin;
}
.profileContainer #react-select-2-listbox::-webkit-scrollbar{
  display: inherit;
  width: 6px;
  height: 2px;
  border: 1px solid var(--PRIMARY);
}
.profileContainer #react-select-2-listbox::-webkit-scrollbar-track {
  width: 4px;
  background: var(--SUBGRAY);
  height: 6px;
}
.profileContainer #react-select-2-listbox::-webkit-scrollbar-thumb {
  background: var(--PRIMARY);
  width: 1px;
  height: 2px;
  border-radius: 6px;
}

.logoBack {
  position: absolute;
  left: 20px;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  background-color: var(--WHITE);
  filter: drop-shadow(0px 0px 10px rgba(8, 30, 29, 0.3));
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -36px;
}

.textField {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formHeader {
  color: var(--WHITE);
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_700);
}

.formGroup {
  position: relative;
}

.formGroup .inline {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.form-control {
  padding: 12px 40px 12px 16px;
  width: 100%;
  height: 48px;
  background-color: var(--WHITE);
  font-family: Arial, Helvetica, sans-serif;
  font-size: var(--FONT16);
  font-weight: var(--FONT_WEIGHT_400);
  color: var(--LIGHTGRAY);
  border-radius: 8px;
  position: relative;
}

.form-control:focus {
  border: 1px solid var(--PLACEHOLDER);
  color: var(--LIGHTGRAY);
  font-family: Arial, Helvetica, sans-serif;
  font-size: var(--FONT16);
  outline: none;
  box-shadow: none !important;
  border-right: 0;
}

.form-control::placeholder {
  color: var(--PLACEHOLDER);
}

.form-control::-moz-placeholder {
  color: var(--PLACEHOLDER);
}

.inputIcon {
  position: absolute;
  right: 0;
  top: 0;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--PLACEHOLDER);
  font-size: 24px;
}

.formlabel {
  display: block;
  margin-bottom: 6px;
  font-size: var(--FONT13);
  font-weight: var(--FONT_WEIGHT_500);
  color: var(--WHITE);
}

.formlabel span {
  color: var(--RED);
}

.errorMsg {
  margin-top: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--RED) !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: var(--FONT13);
}

.errorMsg span {
  font-size: var(--FONT13);
  color: var(--RED);
  display: block;
  margin-right: 8px !important;
  height: 16px !important;
  display: flex;
  align-items: center;
}

.errorMsg span .icon {
  font-size: 16px;
}

.passwordcustom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.passwordcustom span {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.passwordcustom span.green .icon {
  color: var(--SECONDARY);
}

.errorMsg span.green {
  color: var(--SECONDARY);
}

.togglePasswordButton {
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--PLACEHOLDER);
  font-size: 24px;
}

.togglePasswordButton i {
  color: var(--GRAY);
  font-size: var(--FONT16);
  background-color: var(--WHITE);
}

.togglePasswordButton.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.form button {
  width: 100%;
}

.matchHeader span {
  color: var(--SECONDARY);
}

.custom-btn:hover,
.custom-btn:active,
.custom-btn {
  color: var(--PRIMARY);
  height: 48px;
  font-family: var(--Faktum-Test);
  font-weight: var(--FONT_WEIGHT_800);
  font-size: var(--FONT16);
  border-radius: 8px;
  background-color: var(--SECONDARY);
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

button.btn.custom-btn span {
  padding-bottom: 3px;
  color: var(--PRIMARYVARIANT) !important;
}

.custom-btn:disabled {
  color: var(--BTNDISABLETEXT) !important;
  opacity: 0.5;
  background-color: var(--SECONDARY);
  text-transform: uppercase;
}

.cardContainer button {
  width: 100% !important;
}

.purchaseContainer {
  display: flex;
  gap: 32px;
  color: var(--WHITE);
  transform: scale(1);
}

/* .purchaseContainer div:nth-child(3) .cardBack {
  border: 1px solid var(--SECONDARY);
}
.purchaseContainer div:nth-child(3) span {
  color: var(--SECONDARY) !important;
} */
.loginOpt {
  color: var(--WHITE);
  text-align: center;
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_400);
  font-family: var(--Aeonik-TRIAL);
  margin-bottom: 0 !important;
  letter-spacing: 0.5px;
}

.cardBack .sideSelectedBack {
  background-color: var(--SECONDARY) !important;
  color: var(--PRIMARY) !important;
}

.infoIcon .icon {
  font-size: 16px;
}

.wrapper {
  position: relative;
  padding-top: 0px;
  width: 100%;
}

.dashboardLogo {
  margin-bottom: 50px;
}

.fade.customModal.modal.show {
  padding-left: 0px !important;
}

.sidebar {
  position: fixed;
  left: 0;
  width: 230px;
  height: 100%;
  border-right: 1px solid var(--WHITE);
  padding: 40px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: var(--FONT_WEIGHT_800);
}

.sidebarMenu {
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.sidebarMenu p {
  margin-bottom: 0;
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_800);
  letter-spacing: 1px;
}

.sidebar nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: var(--FONT13);
  font-weight: var(--FONT_WEIGHT_400);
  color: var(--WHITE);
  flex-direction: column;
  padding: 24px 0px;
  width: 100%;
  border-radius: 10px;
}

.sidebar nav a.active {
  background-color: var(--SECONDARY);
  color: var(--PRIMARY);
}

.sidebar nav a span {
  font-size: var(--FONT24);
  margin-bottom: 8px;
}

.sidebar nav a:hover {
  color: var(--PRIMARY);
  background: var(--SECONDARY);
}

.sideMenu {
  margin-bottom: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.logOut {
  color: #fff;
  font-size: var(--FONT12);
  font-weight: var(--FONT_WEIGHT_700);
  line-height: normal;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-decoration: none;
  border: none;
  background-color: transparent;
}

.logOut-inner {
  color: #fff;
  font-size: var(--FONT12);
  font-weight: var(--FONT_WEIGHT_700);
  line-height: normal;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-decoration: none;
  border: none;
  background-color: transparent;
}

.logOut-inner:hover {
  background-color: transparent;
  border: none;
}

.logOut-inner:active {
  background-color: transparent !important;
  border: none;
}

.logOut:hover {
  background-color: transparent;
  border: none;
}

.logOut span {
  font-size: var(--FONT24);
}

.main-content {
  position: relative;
  margin-left: 231px;
}

.dashboardTitle {
  color: var(--SECONDARY);
  font-family: var(--Faktum-Test);
  font-size: var(--FONT40);
  font-weight: var(--FONT_WEIGHT_800);
  letter-spacing: -1.5px;
  padding: 32px 0 8px 0;
  margin-bottom: 0;
}

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardContainer {
  padding: 64px 64px 0 64px;
}

.dashboardDescription {
  color: var(--WHITE);
  font-family: var(--Aeonik-TRIAL);
  padding-bottom: 32px;
  margin-bottom: 0;
  font-size: var(--FONT18);
  max-width: 683px;
  word-break: break-word;
}

.subCards {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 24px;
  padding-top: 40px;
}
.stickBox {
  padding-bottom: 20px;
}
.subCards div:nth-child(1) {
  background: var(--SUBBLUE);
}

.subCards div:nth-child(2) {
  background: var(--SUBLIGHTBLUE);
}

.subCards div:nth-child(3) {
  background: var(--SUBORANGE);
}

.subCards div:nth-child(4) {
  background: var(--SUBPURPLE);
}

.matchCards {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  scrollbar-width: none;
}

.matchCardDiv {
  padding-bottom: 24px;
}

.matchCards::-webkit-scrollbar {
  overflow: auto;
  width: 0;
  display: inherit;
}

.dashboardLogo {
  margin-bottom: 50px;
  max-width: 100%;
  height: auto;
}

.onboardContainer {
  background-color: transparent;
}

.onboardingCards {
  display: flex;
}

.onboardingCard {
  padding: 24px;
  border-radius: 19px;
  font-family: var(--Faktum-Test);
  color: var(--PRIMARY);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.onboardMain .onboardingCards .leftSubContainer {
  color: var(--WHITE);
  position: absolute;
  bottom: 33px;
  left: 28px;
}

.onboardMain .onboardingCards .matchRightContainer {
  position: absolute;
  bottom: 33px;
  right: 28px;
}

.onboardingCard .cardHeader h1 {
  color: var(--WHITE);
  font-size: var(--FONT38);
  font-weight: var(--FONT_WEIGHT_800);
  letter-spacing: -1.25px;
  text-align: left;
  margin-bottom: 0px;
  /* word-break: break-word; */
}

.userWrapper .onboardingCard .cardHeader h1 {
  width: 70%;
  /* word-break: break-word; */
}

.loginIcons img {
  cursor: pointer;
}

.onboardingCard .cardHeader {
  display: flex;
  justify-content: space-between;
}
.onboardingCard .cardHeader div:nth-child(2) {
  letter-spacing: 1px !important;
  font-size: var(--FONT48);
}
.onboardingCard .cardHeader div:nth-child(2) {
  padding: 3px 9px;
  border-radius: 9.37px;
  background-color: var(--WHITE);
  height: max-content;
  font-size: var(--FONT14);
  min-width: 78px;
  font-weight: var(--FONT_WEIGHT_800);
}

.onboardingCard div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  
}

.onboardingCard .cardSubHeader h6 {
  font-size: var(--FONT24);
  /* word-break: break-word; */
}

.userWrapper .onboardingCard .cardSubHeader h6 {
  font-weight: var(--FONT_WEIGHT_800);
  margin-bottom: 0px;
  width: 64%;
  /* word-break: break-word; */
}

.onboardingCard div:nth-child(2) h6 {
  font-weight: var(--FONT_WEIGHT_800);
  margin-bottom: 0px;
}

.onboardingCard .cardSubHeader span {
  font-weight: var(--FONT_WEIGHT_800);
  text-align: right;
  /* letter-spacing: 0.1px; */
  /* word-break: break-word; */
  font-size: var(--FONT10);
}

.onboardingCard .imageContainer {
  position: relative;
}

.imageContainer .leftSubContainer {
  position: absolute;
  bottom: 14px;
  left: 8px;
  padding: 4px 14px;
  border-radius: 15px;
  font-size: var(--FONT18);
  letter-spacing: -1.5px;
  width: fit-content;
  font-weight: var(--FONT_WEIGHT_800);
}

.imageContainer span {
  font-size: var(--FONT14);
}

.imageContainer .matchRightContainer {
  position: absolute;
  bottom: 14px;
  right: 12px;
  padding-top: 7px;
}

.cardContainer {
  padding: 27px;
  font-weight: var(--FONT_WEIGHT_800);
  gap: 24px;
  border-radius: 12px;
  box-shadow: 0px 8px 16px 0px rgba(17, 17, 17, 0.04);
  color: var(--PRIMARY);
  font-family: var(--Faktum-Test);
}

.cardContainer h1 {
  font-size: var(--FONT48);
  font-weight: var(--FONT_WEIGHT_800);
  letter-spacing: -1px;
  margin-bottom: 0;
}

.cardTitle {
  font-size: var(--FONT18);
  letter-spacing: -1px;
  margin-bottom: 0;
}

.matchHeader {
  color: var(--WHITE);
  font-size: var(--FONT32);
  font-weight: var(--FONT_WEIGHT_800);
  padding-top: 48px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--WHITE);
  letter-spacing: -1px;
  margin-bottom: 20px;
  font-family: var(--Faktum-Test);
}

.search-header {
  color: var(--WHITE);
  font-size: var(--FONT32);
  font-weight: var(--FONT_WEIGHT_800);
  margin-right: 64px;
  border-bottom: 1px solid var(--WHITE);
  font-family: var(--Faktum-Test);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-header span {
  color: var(--SECONDARY);
  font-size: var(--FONT20);
}

.error-message {
  color: var(--WHITE);
  font-size: var(--FONT20);
  font-weight: var(--FONT_WEIGHT_800);
  padding-top: 24px;
  font-family: var(--Faktum-Test);
  text-align: center;
}
.no-data-message{
    height: 248px;
    text-align: center;
    width: 100%;
    font-size: 22px;
}

.no-message{
  height: 230px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;

}

.matchCard {
  display: flex;
  min-height: 200px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 0;
  font-weight: var(--FONT_WEIGHT_800);
  flex: 1 0;
  border-radius: 24px;
  background-color: var(--SUBGREEN);
  box-shadow: 0px 8px 16px 0px rgba(17, 17, 17, 0.04);
  color: var(--PRIMARY);
  font-family: var(--Faktum-Test);
  position: relative;
  cursor: pointer;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}

#error-msg {
  margin-top: -6px;
}

#error-msg span {
  margin-top: -6px;
  font-size: var(--FONT16);
}

.subContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftSubContainer {
  background-color: var(--WHITE);
  display: flex;
  width: 139px;
  height: 34px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 7.943px;
  border-radius: 14.297px;
  margin-bottom: 0;
}

.leftSubContainer .text-label {
  font-size: var(--FONT16);
  font-weight: var(--FONT_WEIGHT_800);
}

.leftSubContainer .icon-aeroplane {
  font-size: var(--FONT13);
}

.rightSubContainer {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  background-color: var(--WHITE);
  border-radius: 12px;
  margin-bottom: 0;
  white-space: nowrap;
}

.matchBody {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  /* max-width: 73%; */
}
.subMatchBody {
  width: 75%;
}
.button-update a {
  text-decoration: none;
  color: var(--SECONDARY);
}

.matchTitle {
  font-size: 2rem;
  font-weight: var(--FONT_WEIGHT_800);
  line-height: 40px;
  margin-top: 14px;
  margin-bottom: 0px;
  letter-spacing: -1px;
  word-break: break-word;
  width: 100%;
}
.word-correct-spacing{
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-transform: uppercase;
  word-break: inherit;
}

.word-correct-spacing{
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-transform: uppercase;
  word-break: inherit;
}


.matchSubTitle {
  font-size: var(--FONT18);
  font-weight: var(--FONT_WEIGHT_800);
  text-transform: uppercase;
  letter-spacing: -1px;
  margin-bottom: 0px;
  max-width: 241px;
  word-break: break-word;
}

.matchRightContainer {
  border-radius: 38px;
  background: var(--WHITE);
  width: 80px;
  height: 80px;
  padding: 0;
  /* position: absolute;
  right: 24px; */
  font-size: 30px;
  font-weight: var(--FONT_WEIGHT_800);
  display: flex;
  align-items: center;
  justify-content: center;
}

.matchRightContainer span {
  font-size: var(--FONT24);
  position: relative;
  top: -13px;
}

.purchaseCardContainer {
  color: var(--WHITE);
  font-family: var(--Aeonik-TRIAL);
  max-width: 485px;
}

.purchaseCardContainer button {
  width: 100% !important;
  margin-top: 32px !important;
}

.purchaseContainer div:nth-child(3) button {
  margin-top: 26px !important;
}

.purchaseCardContainer span {
  color: var(--SECONDARY);
  font-size: var(--FONT24);
}

.purchaseCardContainer p {
  margin-bottom: 12px;
  /* word-break: break-word; */
}

.purchaseCardContainer hr {
  width: 100% !important;
  color: var(--GREY);
  padding-bottom: 24px;
}

.cardBack {
  padding: 56px 40px 56px 40px;
  border-radius: 24px;
  border: 1px solid var(--WHITE);
}

.purchaseCardContainer .cardBack {
  height: 768px;
}

.selecetCardBack {
  border: 1px solid var(--SECONDARY);
}

.cardBack .sideContainer {
  padding: 8px 16px;
  border-radius: 24px;
  border: 1px solid var(--SECONDARY);
  color: var(--SECONDARY);
  background-color: transparent;
  width: fit-content;
}

.cardBack .sideSubContainer {
  font-size: 64px;
  font-weight: 800;
  line-height: 44px;
  letter-spacing: -1px;
  text-align: left;
}
.purchaseDiv {
  min-height: 70px;
}
.cardBack .sideMain {
  margin-top: 32px;
  margin-bottom: 24px;
}
.modal-content .cardBack .sideMain {
  margin-top: 32px;
  margin-bottom: 6px;
}

.purchaseContainer div:nth-child(3) .cardBack .sideMain {
  margin-top: 32px;
  margin-bottom: 19px;
}

.purchaseCardContainer .purchaseCardContainer button {
  margin-top: 23px !important;
}

.sideMain .sideSubContainer {
  color: var(--WHITE) !important;
  margin-right: 10px;
}

.cardList {
  display: flex;
  gap: 16px;
  align-items: center;
}

.imageContainer .matchRightContainer span {
  font-size: var(--FONT28);
}

.onboardingCards .onboardingCard:nth-child(1) {
  background: var(--SUBRED);
  z-index: 10;
}

.onboardingCards  .leftSubContainer{
  font-weight: var(--FONT_WEIGHT_800);
}

.onboardingCards .onboardingCard:nth-child(1) .leftSubContainer {
  background: var(--SUBRED);
  color: var(--WHITE);
}

.onboardingCards .onboardingCard:nth-child(2) {
  background: var(--SUBBLUE);
  margin-left: -50px;
  z-index: 20;
}

.userWrapper .onboardingCards {
  gap: 16px;
  overflow-x: auto;
  margin-right: 16px;
}

.onboardingCards::-webkit-scrollbar {
  overflow-x: auto;
  width: 0;
  /* display: inherit; */
}
.dashboardDiv::-webkit-scrollbar {
  height: 6px;
  border: 1px solid var(--SECONDARY);
  display: inherit;
}
.dashboardDiv::-webkit-scrollbar-track {
  border-radius: 0;
  width: 1px;
  background: var(--GRAY);
  height: 6px;
}

.dashboardDiv::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: var(--SECONDARY);
  width: 2px;
  height: 6px;
}

.swiper-scrollbar {
  height: 6px !important;
  background-color: var(--GRAY);
}

.swiper-scrollbar-drag {
  background: var(--SECONDARY);
  /* border-radius: 6px; */
}

.userWrapper .onboardingCards .onboardingCard:nth-child(2) {
  margin-left: 0px;
}

.onboardingCards .onboardingCard:nth-child(2) .leftSubContainer {
  background: var(--SUBBLUE);
  color: var(--WHITE);
}

.onboardingCards .onboardingCard:nth-child(3) {
  background: var(--SUBGREEN);
  margin-left: -50px;
  z-index: 30;
}

.userWrapper .onboardingCards .onboardingCard:nth-child(3) {
  margin-left: 0px;
}

.onboardingCards .onboardingCard:nth-child(3) .leftSubContainer {
  background: var(--SUBGREEN);
  color: var(--WHITE);
}

.onboardingCards .onboardingCard:nth-child(4) {
  background: var(--SUBPURPLE);
  margin-left: -50px;
  z-index: 40;
}

.onboardingCards .onboardingCard:nth-child(4) .leftSubContainer {
  background: var(--SUBPURPLE);
  color: var(--WHITE);
}

.userWrapper .onboardingCards .onboardingCard:nth-child(4) {
  margin-left: 0px;
}

.onboardingCards .onboardingCard:nth-child(5) {
  background: var(--SUBORANGE);
  margin-left: -50px;
  z-index: 50;
}

.userWrapper .onboardingCards .onboardingCard:nth-child(5) {
  margin-left: 0px;
}

.onboardingCards .onboardingCard:nth-child(5) .leftSubContainer {
  background: var(--SUBORANGE);
  color: var(--WHITE);
}

.onboardingMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.onboardMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  overflow: auto;
  padding: 32px;
}

.workMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.onboardingContainer button {
  width: 127px;
  margin-top: 40px;
}

.onboardingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.userWrapper .onboardingCard {
  display: flex;
  flex-direction: column;
  max-height: 404px;
  width: 364px;
  overflow: hidden;
  position: relative;
  gap: 0;
}

.userWrapper .onboardingCards .matchRightContainer {
  position: absolute;
  bottom: 50px;
  right: 35px;
}

.userWrapper .onboardingCards img {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-weight: var(--FONT_WEIGHT_800);
  border-radius: 32px;
  object-fit: cover;
  object-position: center top;
}

.userWrapper .onboardingCards .leftSubContainer {
  color: var(--WHITE);
  position: absolute;
  bottom: 50px;
  left: 35px;
}

.bottomContainer {
  margin-top: 80px;
}

.bottomContainer div {
  display: flex;
  justify-content: center;
}

.bottomContainer span {
  color: var(--ICONBLUE);
  margin: 0 4px;
  cursor: pointer;
}

.subTitleContainer {
  color: var(--WHITE);
  font-family: var(--Aeonik-TRIAL);
  margin-bottom: 0;
  font-size: var(--FONT18);
  max-width: 683px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
}

.subTitleContainer h1 {
  color: var(--SECONDARY);
  font-family: var(--Faktum-Test);
  font-size: var(--FONT40);
  font-weight: var(--FONT_WEIGHT_800);
  letter-spacing: -1px;
  padding: 32px 0 8px 0;
  margin-bottom: 0;
  text-align: center;
  margin-bottom: 0;
}

.subTitleContainer p {
  margin-bottom: 0;
  text-align: center;
  max-width: 785px;
  padding: 0 10px;
  /* word-break: break-word; */
}

.searchContainer {
  width: 51%;
  display: flex;
}

.searchContainer .searchFilter input {
  padding: 14px 36px 14px 16px;
  border-radius: 6px;
  border: 1px solid var(--SUBGRAY);
  margin-right: 10px;
  width: 100%;
}

.searchContainer input:focus {
  outline: none;
}

.searchFilter {
  display: flex;
  position: relative;
}

.searchFilter .icon-cross {
  position: absolute;
  top: 33%;
  right: 15px;
  font-size: 20px;
}

.searchContainer input::placeholder {
  font-size: var(--FONT16);
  font-weight: var(--FONT_WEIGHT_400);
  font-family: "Inter", sans-serif;
  color: var(--PLACEHOLDER);
}

.userContainer .searchFilter .icon-cross {
  position: absolute;
  top: 33%;
  right: 20px;
  font-size: 20px;
}

.filter-group {
  display: flex;
  gap: 10px;
  margin-top: 16px;
  margin-bottom: 24px;
}

.date-div {
  position: relative;
}

.react-datepicker-wrapper {
  height: 54px;
  background-color: var(--PRIMARY);
  color: var(--WHITE);
  position: relative;
  border-radius: 4px;
  border: none;
}

.react-datepicker-wrapper .toggle-button {
  font-size: var(--FONT14);
  white-space: nowrap;
}

.react-datepicker-wrapper .react-datepicker-ignore-onclickoutside {
  height: 54px;
  background-color: var(--PRIMARY);
  color: var(--WHITE);
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--WHITE);
}

.events-container .dateContainer .react-datepicker__header {
  border-bottom: 0.8px solid var(--BORDERGRAY) !important;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  padding: 5px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  border-radius: 99px;
  background-color: var(--PRIMARY);
  color: var(--SECONDARY) !important;
}

.react-datepicker__header .react-datepicker-year-header {
  margin-top: 0;
  font-family: var(--Aeonik-TRIAL);
  color: var(--PRIMARY);
  font-weight: var(--FONT_WEIGHT_400);
  font-size: var(--FONT14);
  border-bottom: 0.8px solid var(--BORDERGRAY);
  padding-bottom: 18px;
}

.event-picker {
  height: 54px;
  background-color: var(--PRIMARY);
  color: var(--WHITE);
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--WHITE);
}

.date-div .icon-cross {
  position: absolute;
  color: var(--WHITE);
  font-size: var(--FONT18);
  right: 15px;
  top: 18px;
}

.react-datepicker-wrapper .react-datepicker__input-container .datepicker {
  background-color: var(--PRIMARY);
  height: 54px;
  color: var(--WHITE);
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_800);
  text-align: center;
  border: none;
  border-radius: 10px;
}

/* //user dashboard  */
.user-dashboard-date {
  display: relative;
}

.user-dashboard-date
  .react-datepicker__input-container
  .react-datepicker__close-icon::after {
  position: absolute;
  color: var(--WHITE);
  font-size: var(--FONT18);
  right: 5px !important;
  top: 17px;
  right: 5px;
  padding-left: 5px;
}

.eventsHeading .matchRightContainer {
  position: relative;
  right: 0;
}

.eventsHeading #subRightMain {
  flex-direction: column;
  align-items: end;
}

.react-datepicker__input-container input::placeholder {
  color: var(--WHITE);
}

.react-datepicker-popper {
  z-index: 101;
  background-color: var(--WHITE);
}

#dateMainDiv
  .react-datepicker__input-container
  .react-datepicker__close-icon::after {
  background-color: transparent;
  font-size: 22px;
  position: absolute;
  top: 13px;
  right: 6px;
  color: var(--SECONDARY);
}

.toggle-button {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_800);
  justify-content: center;
  background-color: var(--PRIMARY);
  border-radius: 4px;
  color: var(--WHITE);
  border: none;
  width: 220px;
}

.clear-btn {
  background: transparent;
  color: var(--PRIMARY);
  outline: none;
  text-decoration: underline;
  border: none;
  padding: 16px 24px;
  align-items: center;
  font-size: var(--FONT15);
  font-weight: var(--FONT_WEIGHT_800);
  justify-content: center;
  white-space: nowrap;
}

.active-toggle {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_800);
  justify-content: center;
  background-color: var(--PRIMARY);
  border-radius: 4px;
  color: var(--SECONDARY);
  border: none;
  width: 220px;
}

.dropdown-option {
  display: flex;
  flex-direction: column;
  background-color: var(--WHITE);
  position: relative;
  z-index: 101;
  font-family: Arial, Helvetica, sans-serif;
}

.userWrapper .dropdown-option {
  position: absolute;
  width: 182px;
}

.dropdown-option label {
  padding: 10px;
}

.dropdown-option input[type="checkbox"]:checked + label {
  background-color: green;
}

.react-select__indicators .css-1hb7zxy-IndicatorsContainer {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_800);
  justify-content: center;
  background-color: var(--PRIMARY);
  border-radius: 4px;
  color: var(--WHITE);
  border: none;
  height: 54px;
  max-width: 220px;
}

.css-1fdsijx-ValueContainer {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_800);
  justify-content: center;
  background-color: var(--PRIMARY);
  border-radius: 4px;
  color: var(--WHITE);
  border: none;
  width: 100%;
  height: 54px;
  max-width: 220px;
}

.google-places-auto-complete .css-1fdsijx-ValueContainer {
  max-width: 100% !important;
  background-color: var(--WHITE);
  padding: 8px 12px;
  justify-content: flex-start !important;
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_400);
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 8px;
  height: 46px;
}

.google-places-auto-complete .css-t3ipsp-control {
  max-width: 100% !important;
  background-color: var(--WHITE);
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_400);
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: none !important;
  border: none !important;
  border-radius: 8px !important;
  height: 46px;
}

.css-13cymwt-control {
  max-width: 100% !important;
  background-color: var(--WHITE);
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_400);
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: none !important;
  border: none !important;
  border-radius: 8px !important;
  height: 46px;
}

.css-t3ipsp-control:hover {
  border: none;
  box-shadow: none;
}

.css-1fdsijx-ValueContainer .css-1jqq78o-placeholder {
  color: var(--PLACEHOLDER) !important;
}

.filter-group .css-1xc3v61-indicatorContainer {
  height: 100%;
  background-color: var(--PRIMARY) !important;
}

.react-select__indicators.css-1hb7zxy-IndicatorsContainer {
  background-color: var(--PRIMARY) !important;
}

.userWrapper .sidebar {
  width: 116px;
  z-index: 200;
}

.userWrapper .sidebarMenu {
  padding: 0px 10px;
}

.userWrapper .sidebar nav a span {
  margin-bottom: 0px;
}

.userWrapper .sidebar img {
  padding-top: 14px;
  margin-bottom: 52px;
}

/* ----- dropdown style------ */

.filter-group .dropdown .show {
  color: var(--SECONDARY);
}

.filter-group .dropdown button {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_800);
  justify-content: center;
  background-color: var(--PRIMARY);
  border-radius: 4px;
  color: var(--WHITE);
  border: none;
  width: 220px;
}

.tableHeader button {
  font-family: var(--Faktum-Test);
  display: flex;
  align-items: center;
  gap: 11px;
}

.selectedOption {
  display: flex;
  align-items: center;
  gap: 11px;
  white-space: nowrap;
  padding: 8px 16px;
}

.extra-class {
  padding: 16px 24px !important;
  width: 132px !important;
}

.tableHeader span {
  font-size: var(--FONT24);
  color: var(--PRIMARYVARIANT);
}

.profileImgContainer {
  position: relative;
}

.profileImgContainer img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.profileIconBack {
  background-color: var(--SECONDARY);
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  color: var(--BLACK);
  position: absolute;
  bottom: 4px;
  right: -1px;
  cursor: pointer;
}

.SubTitleContainer h2 {
  font-family: var(--Faktum-Test);
  font-size: var(--FONT40);
  font-weight: var(--FONT_WEIGHT_800);
  letter-spacing: -1px;
  color: var(--SECONDARY);
  margin-bottom: 40px;
  /* word-break: break-word; */
}

.howItWorks {
  display: flex;
  min-height: 456px;
  justify-content: center;
  flex-direction: column;
}

.howItWorks .subContainer {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  height: 100%;
}

.howItWorks .whiteHeaderBack {
  background-color: var(--WHITE);
  color: var(--DARKPRIMARY);
  border-radius: 12px;
  padding: 13px 15px;
}

.howItWorks .secondaryBack {
  background-color: var(--SECONDARY);
  color: var(--DARKPRIMARY);
  border-radius: 12px;
  padding: 8px 14px;
}

.howItWorks .primaryBack {
  background-color: var(--DARKPRIMARY);
  color: var(--WHITE);
  border: 1px solid var(--WHITE);
  border-radius: 14px;
  padding: 8px 14px;
}

.step1Container {
  position: relative;
  display: flex;
  justify-content: center;
}

.step3Container {
  position: relative;
  width: 212px;
}

.step2Container .primaryBack {
  width: 140px;
  height: 48px;
}

.step2Container .secondaryBack {
  width: 140px;
  height: 48px;
}

.step2Container .whiteHeaderBack {
  width: 140px;
  height: 48px;
}

.secondaryTitle {
  background-color: var(--SECONDARY);
  border-radius: 24px;
  padding: 20px 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  font-size: var(--FONT24);
  font-weight: var(--FONT_WEIGHT_800);
  color: var(--DARKPRIMARY);
  font-family: var(--Faktum-Test);
  width: 103px;
  position: absolute;
  top: 56px;
  left: 75px;
}

.step3Container {
  position: relative;
  height: 281px;
}

.step3Container img {
  margin-bottom: 35px;
}

.howItWorks .step1Container .primaryBack {
  position: absolute;
  top: 40px;
  right: 56px;
  height: 41px;
}

.howItWorks .step1Container .secondaryBack {
  position: absolute;
  top: 90px;
  left: 46px;
  height: 41px;
}

.step2Container .secondaryBack {
  margin-bottom: 2px;
}

.step4Container div {
  position: relative;
  display: flex;
  justify-content: center;
}

.step4Container .primaryBack .cardTitle {
  padding-right: 0;
}

.step4Container .secondaryBack .cardTitle {
  padding-right: 0;
}

.step4Container .primaryBack {
  position: absolute;
  top: 49px;
  left: 120px;
  width: max-content;
  height: 41px;
}

.step4Container .secondaryBack {
  position: absolute;
  bottom: 12px;
  right: 98px;
  height: 41px;
  width: max-content;
}

.howItWorks .icon-arrow {
  color: var(--SECONDARY);
}

.step1Container {
  position: relative;
}

.secondaryTitle span {
  color: var(--DARKPRIMARY);
  transform: rotate(-90deg);
}

.step3Container img {
  margin-bottom: 20px;
}

.step2Container .stepSubContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.trip {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.trip span {
  font-family: var(--Faktum-Test);
  font-weight: var(--FONT_WEIGHT_800);
}

.cardTitle {
  font-family: var(--Faktum-Test);
  font-weight: var(--FONT_WEIGHT_800);
  margin-top: 2px;
  padding-right: 30px;
  /* word-break: break-word; */
}

.howItWorks .subContainer .subBackContainer {
  background-color: var(--WHITE);
  color: var(--DARKPRIMARY);
  border: 1px solid var(--DARKPRIMARY);
  border-radius: 24px;
  height: 146px;
  width: 122px;
  text-align: center;
  position: relative;
}

.subBackContainer h6 {
  padding-top: 12px;
  font-weight: var(--FONT_WEIGHT_700);
  font-size: var(--FONT14);
}

.cardHeader .trip {
  padding: 14px 15px;
  border-radius: 14px;
  display: flex;
  gap: 3px;
  align-items: center;
}

.stepSubTitle {
  color: var(--SECONDARY);
  margin-top: 80px;
  text-align: center;
  width: 180px;
  font-family: var(--Faktum-Test);
  font-size: var(--FONT20);
  font-weight: var(--FONT_WEIGHT_800);
  letter-spacing: 0px;
}

.stepSubTitle p {
  font-size: var(--FONT14);
  font-family: var(--Aeonik-TRIAL);
  font-weight: var(--FONT_WEIGHT_400);
  letter-spacing: 0em;
  text-align: center;
  color: var(--WHITE);
}

.workMainContainer {
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.profileContainer .form-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 12px 16px;
}

.profileContainer .form-control span {
  color: var(--PLACEHOLDER);
}

.profileContainer .form-control .selected {
  color: var(--LIGHTGRAY);
}

.formDropdown {
  position: relative;
}

.profileContainer .dropdown-option {
  border-radius: 8px;
  width: 100%;
  margin-top: 2px;
  max-height: 130px;
  overflow: auto;
  scrollbar-color: var(--PRIMARY) transparent;
  scrollbar-width: thin;
  border: 1px solid var(--WHITE);
}
.profileContainer .dropdown-option::-webkit-scrollbar{
  display: inherit;
  width: 6px;
  height: 2px;
  border: 1px solid var(--PRIMARY);
}
.profileContainer .dropdown-option::-webkit-scrollbar-track {
  width: 4px;
  background: var(--SUBGRAY);
  border-radius: 24px;
}
.profileContainer .dropdown-option::-webkit-scrollbar-thumb {
  background: var(--PRIMARY);
  width: 1px;
  height: 2px;
  border-radius: 6px;
}
.profileContainer .form-control span:nth-child(2) {
  font-size: var(--FONT24);
  color: var(--PRIMARYVARIANT);
  opacity: 0.5;
}

.profileContainer .dropdown-option label {
  border-bottom: 1px solid var(--GRAYVARIANT);
}

.profileContainer .dropdown-option label:nth-child(1):hover {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.profileContainer .dropdown-option label:nth-child(3):hover {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.profileContainer .dropdown-option label:last-child {
  border-bottom: none;
}

.dropdown-option label:hover {
  background-color: var(--PLACEHOLDER);
  color: var(--PRIMARY);
}

.userWrapper .logOut {
  display: flex;
  white-space: nowrap;
  flex-direction: column;
}

.userWrapper .logOut span {
  font-size: var(--FONT22);
  margin-bottom: 2px;
}

.userWrapper .main-content {
  position: relative;
  margin-left: 116px;
}

.userWrapper .dashboardContainer {
  /* min-height: 100vh; */
  padding: 64px 0 0 64px;
}

.userWrapper .subContent {
  padding-right: 64px;
}

.userWrapper #subHeader {
  border-bottom: 1px solid var(--SECONDARY);
  margin-right: 64px;
}

.userWrapper .matchHeader {
  margin-right: 64px;
}

.userWrapper .eventsLinksContainer .matchHeader {
  margin-right: 0;
}

.userWrapper .matchCards {
  margin-right: 64px;
}

.userWrapper .tableHeader .sortContainer {
  display: none;
}

.userWrapper .headerContainer h1 {
  color: var(--WHITE);
}

.userWrapper .dashboardTitle {
  padding-top: 32px;
  word-break: break-word;
}
/* .userWrapper .dashboardTitle span{
  word-break:break-all;
} */

.userWrapper .dashboardDescription {
  padding-bottom: 32px;
  word-break: break-word;
}

.userWrapper .headerContainer {
  padding-top: 24px;
  /* height: 195px; */
}

.calendar-section .headerContainer {
  padding-top: 24px;
}

.onboardingCards img {
  width: 317px;
  height: 265px;
}

.onboardingCard .cardSubHeader {
  height: auto;
}

.headerContainer .buttonContainer {
  display: flex;
}

.userContainer .searchContainer input {
  width: 686px;
}

.filter-Subgroup {
  gap: 10px;
  margin-top: 16px;
}

.filter-Subgroup .toggle-button {
  border: 1px solid var(--WHITE);
  display: flex;
  padding: 17px 0;
  width: 182px;
  letter-spacing: 1px;
  font-family: var(--Faktum-Test);
  letter-spacing: 1px;
  font-weight: bold;
  /* letter-spacing: 0.5px; */
}

.filter-Subgroup .active-toggle {
  border: 1px solid var(--SECONDARY);
  display: flex;
  padding: 17px 0;
  width: 182px;
  white-space: nowrap;
  font-family: var(--Faktum-Test);
  letter-spacing: 0.1px;
}

#toggleBtn {
  width: 204px;
}
.swiper-no-swiping {
  pointer-events: none;
}
.filter-Subgroup .dropdown-option {
  border: 1px solid var(--SECONDARY);
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 160px;
  overflow: auto;
  scrollbar-color: var(--PRIMARY) var(--SUBGRAY);
  scrollbar-width: thin;
  font-family: Arial, Helvetica, sans-serif;
}

.filter-Subgroup .dropdown-option::-webkit-scrollbar {
  overflow: scroll;
  display: inherit;
  height: 6px;
  border: 1px solid var(--PRIMARY);
}

.filter-Subgroup .dropdown-option::-webkit-scrollbar-track {
  border-radius: 0;
  width: 1px;
  background: var(--SUBGRAY);
  height: 6px;
}

.filter-Subgroup .dropdown-option::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: var(--PRIMARY);
  width: 2px;
  height: 6px;
}
.dashboardDiv::-webkit-scrollbar {
  height: 6px;
  border: 1px solid var(--SECONDARY);
  display: inherit;
}
.dashboardDiv::-webkit-scrollbar-track {
  border-radius: 0;
  width: 1px;
  background: var(--GRAY);
  height: 6px;
}

.dashboardDiv::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: var(--SECONDARY);
  width: 2px;
  height: 6px;
}

/* .userWrapper .filter-Subgroup div:nth-child(1) button {
  font-family: var(--Faktum-Test);
  width: 187px;
} */

.userWrapper .filter-Subgroup .react-datepicker__navigation {
  width: 32px !important;
}

#eventCard {
  background-color: var(--SUBORANGE);
}

#eventCard #subRightMain {
  flex-direction: column;
}

#eventCard #subRightMain .eventLeftContainer {
  padding: 4px 8px;
  font-size: var(--FONT14);
  border-radius: 12px;
}

.eventSubLeft {
  display: flex;
  gap: 16px;
  margin-top: 11px;
}

.eventSubLeft p {
  display: flex;
  align-items: center;
  gap: 6px;
}

.eventSubLeft p:nth-child(2) span {
  transform: rotate(-180deg);
}

.dropdownContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.selectedOption {
  width: 100%;
  cursor: pointer;
  background-color: var(--WHITE);
  width: fit-content;
  color: var(--PRIMARYVARIANT);
  padding: 16px 24px;
  border-radius: 6px;
  border: 1px solid var(--PRIMARYVARIANT);
  font-size: var(--FONT14);
  font-weight: var(--FONT_WEIGHT_800);
  font-family: var(--Faktum-Test);
}

.dropdownList {
  position: absolute;
  left: 0;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: var(--WHITE);
  color: var(--PRIMARYVARIANT);
  border-radius: 0px 0px 6px 6px;
  z-index: 101;
}

.dropdownList li {
  padding: 8px 16px;
  cursor: pointer;
}

.active {
  background-color: var(--PRIMARY);
  color: var(--SECONDARY);
}
.multipleOptionActive{
  display: flex;
  align-items: baseline;
}

.option-value {
  line-height: initial;
}

.dropdownList li:hover {
  background-color: var(--PLACEHOLDER);
  color: var(--PRIMARY);
}

.dropdownList li:last-child {
  border-bottom: none;
}

.football-container {
  padding: 0px 64px 24px;
}

.football-container .subContent {
  padding-top: 64px;
  padding-right: 0px;
}

.match-card-section {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  /* scrollbar-width: thin;
  scrollbar-color: var(--SECONDARY) var(--GRAY); */
}
.football-container .match-card-section .matchCard {
  min-width: auto;
  max-width: auto !important;
}

.swiper-slide .swiper-slide-active {
  width: auto;
}
.swiper-slide {
  width: 404px;
  height: auto !important;
  margin-right: 20px !important;
}

.swiper-wrapper {
  /* /* display: grid;
  grid-auto-flow: column; */
  margin-bottom: 20px;
}
.matchCard {
  height: 100% !important;
}
.match-card-section .matchCard {
  min-width: 404px;
}

.match-card-section::-webkit-scrollbar {
  height: 6px;
  border: 1px solid var(--SECONDARY);
  display: inherit;
}

.match-card-section::-webkit-scrollbar-track {
  border-radius: 0;
  width: 2px;
  background: var(--GRAY);
  height: 6px;
}

.match-card-section::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: var(--SECONDARY);
  width: 2px;
  height: 2px;
}

.football-container #subHeader {
  color: var(--SECONDARY);
  margin-right: 0px;
}

.events-container {
  padding: 0px 64px;
}

.events-heading-section {
  padding-top: 56px;
}

.events-details-container .matchCard {
  max-width: auto;
  min-width: auto;
}
.disableBlur {
  filter: blur(1.85px);
}
.enableBlur {
  filter: blur(5.5px);
}
.calendar-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid white;
  padding-bottom: 16px;
}

.calendar-section .title-bar {
  color: var(--WHITE);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.btn-filter {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  padding: 8px 16px;
  background-color: var(--WHITE);
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  font-weight: var(--FONT_WEIGHT_800);
  border: none;
  cursor: pointer;
  outline: none;
}

.btn-filter .icon-filter {
  font-weight: var(--FONT_WEIGHT_800);
}

.calender-month {
  font-size: var(--FONT24) !important;
  font-weight: var(--FONT_WEIGHT_800) !important;
  margin-right: 24px !important;
  min-width: 133px !important;
}

.calendar-section .total-count {
  color: var(--SECONDARY);
  text-align: end;
}

.calender-main {
  /* height: calc(100vh - 193px); */
  overflow-y: auto;
  scrollbar-width: none;
}

.calender-main::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.calendar-item-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(219px, 1fr));
  gap: 8px;
  margin-top: 4px;
  margin-bottom: 24px;
}

.calendar-item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid white;
  /* max-width: 232px; */
  height: 136px;
  border-radius: 12px;
}

.disable-calendar-item {
  cursor: not-allowed !important;
}

.calendar-item:hover {
  background-color: var(--SECONDARY);
}

.calendar-item .calender-number {
  font-weight: var(--FONT_WEIGHT_800);
  color: var(--WHITE);
  font-size: 14px;
}

.calendar-item:hover .calender-number,
.calendar-item:hover .total-events-item,
.calendar-item:hover .total-events-item .icon-ticket {
  color: var(--PRIMARY);
  font-weight: var(--FONT_WEIGHT_800);
  font-size: 14px;
}

.calendar-item .total-events-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--SECONDARY);
}

.calendar-item .disable-event {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--GRAY);
  font-family: var(--Faktum-Test);
}

.calendar-item .event-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendar-item .event-view .icon-arrow {
  color: var(--SECONDARY);
  margin-left: 6px;
  font-size: 10px;
}

.calendar-item .disable-view .icon-arrow {
  color: var(--GRAY);
  margin-left: 6px;
  font-size: 10px;
}

.calendar-item:hover .event-view .icon-arrow {
  color: var(--PRIMARY);
  font-size: var(--FONT10);
  font-weight: var(--FONT_WEIGHT_800);
}

.calendar-item .disable-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendar-item .disable-view h3 {
  font-size: 14px;
  margin-bottom: 0px;
  color: var(--GRAY);
  border-bottom: 1px solid var(--GRAY);
}

.calendar-item .event-view h3 {
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: var(--FONT_WEIGHT_500);
  color: var(--SECONDARY);
  border-bottom: 1px solid var(--SECONDARY);
}

.calendar-item:hover .event-view h3 {
  color: var(--PRIMARY);
  font-size: 14px;
  font-weight: var(--FONT_WEIGHT_800);
  border-bottom: 1px solid var(--PRIMARY);
}

.calendar-item .event-view h2 {
  font-size: 10px;
  margin-bottom: 0px;
  color: var(--WHITE);
}

.calendar-item .disable-view h2 {
  font-family: var(--Faktum-Test);

  font-weight: bolder;
  font-size: 10px;
  margin-bottom: 0px;
  color: var(--WHITE);
}

.calendar-item:hover .event-view h2 {
  color: var(--PRIMARY);
  font-size: 10px;
  font-weight: var(--FONT_WEIGHT_800);
}

.calendar-item:hover .disable-view h2 {
  color: var(--GRAY);
  font-size: 10px;
  font-weight: var(--FONT_WEIGHT_800);
}

.onboardMain .onboardingContainer {
  margin: 0px;
}

.events-details-container {
  padding: 0px 64px;
}

.events-details-container .match-container {
  margin-top: 20px;
}

.events-details-container .icon-ticket {
  color: var(--SECONDARY);
  font-size: 16px;
}

.eventsLinksContainer {
  padding: 54px 64px 0 64px;
  color: var(--PRIMARY);
  font-weight: var(--FONT_WEIGHT_800) !important;
}

.eventsLinksContainer .back-button {
  margin-bottom: 16px;
  color: var(--SECONDARY);
  font-size: var(--FONT20);
  font-weight: var(--FONT_WEIGHT_700);
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.events-heading-section .back-button {
  display: flex;
  align-items: center;
  color: var(--SECONDARY);
  font-size: var(--FONT20);
  font-weight: var(--FONT_WEIGHT_700);
  gap: 10px;
  cursor: pointer;
}

.eventsLinksContainer .matchTitle {
  margin-top: 0;
}

.eventsHeading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.eventsLinksContainer .eventsHeading .matchTitle {
  font-size: var(--FONT48);
  word-break: break-word;
}

.eventsLinksContainer .eventsHeading .matchSubTitle {
  font-size: var(--FONT32);
  margin-top: 6px;
  max-width: 100%;
  word-break: break-word;
}

.eventsHeading .icon-Travel {
  font-size: var(--FONT230);
  color: var(--GREENVARIANT);
}

.eventsHeading .matchBody {
  max-width: 100%;
  align-items: flex-start;
}

.eventsHeading #subRightMain {
  flex-direction: column;
}

.subContainer .eventLeftContainer {
  background-color: var(--WHITE);
  padding: 8px 16px 6px;
  font-size: var(--FONT20);
  border-radius: 14.3px;
}

.subContainer .eventLeftContainer a {
  text-decoration: none;
  color: var(--PRIMARY);
  cursor: pointer;
  letter-spacing: -1px;
  display: flex;
  gap: 10px;
}

.eventLeftContainer .icon-link {
  font-size: var(--FONT16);
  color: var(--PRIMARY);
  padding-bottom: 4px;
}

.eventsHeading .matchRightContainer {
  position: relative;
}

.eventsHeading .subContainer p {
  font-size: var(--FONT20);
  margin-bottom: 22px;
  margin-top: 10px;
  text-align: end;
  word-break: break-word;
}

.travelSection {
  margin-top: 24px;
}

.travelSection .matchHeader span {
  color: var(--WHITE);
}

.hotelsSection {
  margin-top: 48px;
}

.hotelsSection .subContainer {
  margin-top: 16px;
  align-items: end;
}

.hotelsSection .matchHeader span {
  color: var(--WHITE);
}

.hotelsSection .matchTitle {
  font-size: var(--FONT40) !important;
}

.eventsLinksContainer .hotelsSection .matchCard {
  width: 100%;
  flex: none;
  background-color: var(--SUBLIGHTBLUE);
  word-break: break-word;
}

.hotelsSection .matchCard .matchSubTitle {
  max-width: 100%;
  font-size: var(--FONT24);
}

.hotelsSection .matchRightContainer {
  right: 0;
}

.eventSubContainer {
  display: flex;
  gap: 24px;
}

.eventsLinksContainer .eventsHeading .matchCard {
  flex: none;
}

.eventsLinksContainer #eventsHeader .matchCard {
  width: 771px;
}

.offcanvas {
  background-color: #bbbbbb;
  padding-left: 0px;
  --bs-offcanvas-width: 91%;
  color: var(--PRIMARY);
  font-family: var(--Faktum-Test);
}

.offcanvas-backdrop.show {
  background-color: var(--BLACK);
  opacity: 0.7;
}

.offcanvas-header {
  font-family: var(--Aeonik-TRIAL);
  background-color: var(--WHITE);
  padding: 16px;
  font-size: var(--FONT16);
  font-weight: var(--FONT_WEIGHT_400);
  margin-bottom: 10px;
}

.offcanvas-body {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.offcanvas .sideBarBtn {
  padding: 16px;
  background-color: var(--WHITE);
}

.toggleSideBar {
  background-color: var(--WHITE);
  padding: 6px 16px;
}

.toggleSideBar div {
  border-bottom: 1px solid rgba(164, 164, 164, 0.5);
  padding-top: 26px;
  padding-bottom: 26px;
  letter-spacing: 0.5px;
  font-weight: var(--FONT_WEIGHT_800);
  cursor: pointer;
}

.toggleSideBar div:nth-last-child(1) {
  border-bottom: none;
}

.offcanvas-header .btn-close {
  --bs-btn-close-focus-shadow: none;
}

.offcanvas-body .custom-btn {
  width: 100%;
  border: 1px solid var(--PRIMARY);
}

.offcanvas-title {
  display: flex;
  gap: 8px;
  font-size: var(--FONT16);
  letter-spacing: 1.25px;
}

.dateContainer .react-datepicker__navigation--previous {
  right: 46px;
  left: auto;
}

.dateContainer .react-datepicker__day--selected {
  background-color: var(--PRIMARY);
}

.dateHeader {
  padding: 12.5px 15px 12.5px 20px;
  background-color: var(--WHITE);
}
.event .react-datepicker {
  width: 268px !important;
  font-family: var(--Aeonik-TRIAL);
  font-size: var(--FONT14);
  margin-top: -1px;
  border: none;
  position: absolute;
  border-radius: 0px;
  margin-top: 5px !important;
}

.date-picker-class .active-toggle {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 17px 10px;
}
.date-picker-class .icon-cross {
  margin-right: -26px;
}
.event .active-toggle {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 17px 10px;
}
.event .icon-cross {
  margin-right: -26px;
}

.date-picker-class .react-datepicker {
  width: 268px !important;
  font-family: var(--Aeonik-TRIAL);
  font-size: var(--FONT14);
  margin-top: -1px;
  border: none;
  position: absolute;
  border-radius: 0px;
  margin-top: 5px !important;
}
.date-picker-class .icon-cross {
  font-size: var(--FONT20);
  margin-top: -2px;
}
.event .icon-cross {
  font-size: var(--FONT20);
  margin-top: -2px;
}
.dateContainer .react-datepicker {
  width: 100%;
  font-family: var(--Aeonik-TRIAL);
  font-size: var(--FONT14);
  margin-top: -1px;
  border: none;
  border-radius: 0px;
}

.dateContainer .react-datepicker__month-container {
  width: 100%;
  padding: 24px 15px 24px 20px;
  text-align: left;
  color: var(--PRIMARY);
}

.dateContainer .react-datepicker__header {
  background-color: #ffffff;
  text-align: left;
}

.dateContainer {
  font-family: var(--Aeonik-TRIAL);
  font-size: var(--FONT14);
  color: var(--PRIMARY) !important;
  z-index: 200;
}

.dateContainer .react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
  font-size: var(--FONT12);
  text-transform: uppercase;
}

.dateContainer .react-datepicker__week {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 0 24px;
}

.dateContainer .react-datepicker__navigation--previous {
  right: 46px;
}

.modal-title {
  color: var(--SECONDARY);
}

.modal-content {
  background-color: var(--PRIMARY);
  width: auto;
  padding: 32px 32px 76px 32px;
  border-radius: 24px;
}

.modal {
  --bs-modal: fit-content;
}

.modal-header .btn-close {
  --bs-btn-close-color: var(--SECONDARY);
  cursor: pointer;
}

.icon-close {
  cursor: pointer;
}

.userTables .modal-content {
  background-color: var(--WHITE);
  padding: 16px;
  width: min-content;
}

.signup-modal .modal-content {
  background-color: var(--WHITE);
  padding: 16px;
}

.signup-modal .modal-content .modal-title {
  color: var(--PRIMARY);
}
.signup-modal .modal-content .btn-close {
  font-size: var(--FONT14);
}

.signup-modal .modal-content .active-yes {
  background: var(--PRIMARY);
  border: none;
}
.signup-modal .modal-content .deactive-no {
  background: var(--BLACK);
  border: none;
}

.userTables .modal-content .modal-title {
  color: var(--PRIMARY);
}
.userTables .modal-content .btn-close {
  font-size: var(--FONT14);
}

.userTables .modal-content .active-yes {
  background: var(--PRIMARY);
  border: none;
}
.userTables .modal-content .deactive-no {
  background: var(--BLACK);
  border: none;
}
.userWrapper .filter-Subgroup div:nth-child(1) .dropdown-option {
  width: 204px;
}

.rangeContainer {
  padding: 20px 25px;
  background-color: var(--WHITE);
}

.rangeMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--FONT16);
  font-family: var(--Aeonik-TRIAL);
  height: 32px;
}

.rc-slider-step {
  height: 5px;
}

.rc-slider-rail {
  background-color: var(--GRAYBACK);
}

.rc-slider-track,
.rc-slider-tracks {
  background-color: var(--BLACK);
}

.rc-slider-handle {
  border: solid 2px var(--YELLOWVARIANT);
  opacity: 1;
  background-color: var(--BLACK);
  width: 25px;
  height: 20px;
  margin-top: -8px;
}

.rc-slider-handle:hover {
  border-color: var(--YELLOWVARIANT);
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: var(--YELLOWVARIANT);
  box-shadow: 0 0 0 2px var(--YELLOWVARIANT);
}

#rangeDiv {
  border: 1px solid var(--SECONDARY);
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-top: -2px;
  position: absolute;
  width: 182px;
  z-index: 250;
}

.offcanvas-title span {
  font-size: var(--FONT20);
  cursor: pointer;
}

.offcanvas-header .icon-close {
  font-size: var(--FONT20);
  cursor: pointer;
}

.dateContainer .react-datepicker__day--selected {
  background-color: var(--PRIMARY);
  border-radius: 99px;
  color: var(--SECONDARY) !important;
}

.dateContainer .react-datepicker__day--in-selecting-range:hover {
  background-color: var(--LIGHTPRIMARY);
}

.dateContainer .react-datepicker__day:hover {
  border-radius: 99px;
  background-color: var(--PLACEHOLDER);
}

.dateContainer .react-datepicker__day--in-range {
  border-radius: 99px;
  background-color: var(--PRIMARY);
  color: var(--SECONDARY) !important;
}

.dateContainer
  .react-datepicker__day--in-range
  .react-datepicker__day--weekend {
  border-radius: 99px;
  background-color: var(--LIGHTPRIMARY);
  color: var(--SECONDARY) !important;
}

.rc-slider {
  height: auto;
  padding: 10px 0;
}

.dateContainer .react-datepicker__day--in-selecting-range {
  border-radius: 99px;
  background-color: var(--PRIMARY);
  color: var(--SECONDARY) !important;
}

.dateContainer .react-datepicker__day--keyboard-selected {
  border-radius: 99px;
  background-color: var(--PRIMARY);
  color: var(--SECONDARY) !important;
}

.dateContainer .react-datepicker__day--today {
  font-weight: 400;
}

.dateContainer .react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
  border-color: var(--GREYVARIANT) !important;
}

.dateContainer .react-datepicker__navigation {
  top: 20px;
}

.dateContainer .datepicker__navigation-icon::before {
  border-color: var(--GREYVARIANT) !important;
  height: 7px;
  top: 15px;
  width: 7px;
}

.dateContainer .react-datepicker__current-month {
  margin-top: 0;
  font-family: var(--Aeonik-TRIAL);
  color: var(--PRIMARY);
  font-weight: var(--FONT_WEIGHT_400);
  font-size: var(--FONT14);
  border-bottom: 0.8px solid var(--BORDERGRAY);
  padding-bottom: 18px;
}

.dateContainer .react-datepicker__header {
  border-bottom: none;
}

.events-container .dateContainer .react-datepicker__header {
  border-bottom: 1px solid var(--SUBGRAY);
}

.dateContainer .react-datepicker__day {
  width: 24px;
  height: 24px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dateContainer .react-datepicker__day-names {
  padding: 0px 25px;
  font-size: var(--FONT10);
}

.dateContainer .react-datepicker__day-name {
  color: var(--PRIMARY);
  display: inline-block;
  width: 32px;
  height: 24px;
  line-height: 1.7rem;
  text-align: center;
  margin: 0;
  margin-top: 16px;
}

.dateContainer .react-datepicker__navigation-icon::before {
  border-color: var(--GREYVARIANT);
  border-width: 2px 2px 0 0;
  display: block;
  height: 7px;
  width: 7px;
}

.show-select-option {
  background-color: var(--SECONDARY);
  border: none;
  color: var(--BLACK);
  font-size: var(--FONT16);
  font-weight: var(--FONT_WEIGHT_600);
  padding: 8px 4px 8px 16px;
  border-radius: 8px;
  margin: 10px 10px 0px 0px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.userDashboard-button {
  background-color: var(--WHITE) !important;
  border: none !important;
  padding: 16px 24px;
}

.show-select-option .icon-cross {
  font-weight: var(--FONT_WEIGHT_800);
  font-size: 20px;
}

.userWrapper .searchContainer {
  width: 100%;
}

.userWrapper .searchFilter.d-flex {
  width: 100%;
}

.userWrapper .searchFilter.d-flex input {
  width: 100%;
}

.offcanvas .dateContainer .dropdown-option {
  padding: 16px 16px;
}
.userWrapper .dashboardCards {
  position: relative;
}

.userWrapper .dashboardCards::-webkit-scrollbar {
  overflow: auto;
  width: 0;
  height: 0;
}

.userWrapper .footballdiv {
  overflow-y: auto;
  scrollbar-width: none;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(8, 27, 49, 0.5);
  z-index: 100;
}

.userWrapper .dashboardDiv {
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.userWrapper .dashboardDiv::-webkit-scrollbar {
  display: inherit;
}

.userWrapper .dashboardDiv .custom-btn {
  position: absolute;
  z-index: 110;
  display: flex;
  padding: 23px 53px;
  align-items: center;
  font-size: var(--FONT32);
  height: auto;
  letter-spacing: -2px;
}

.dashboard-content {
  position: relative;
  z-index: 1;
  /* filter: blur(2px); */
}

.modal-header {
  border-bottom: 0px solid;
  font-family: var(--Faktum-Test);
  font-size: var(--FONT40);
  font-weight: var(--FONT_WEIGHT_800);
  letter-spacing: -1px;
  text-align: center;
}

/* .justify-content-between {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
} */

.modal-header span {
  position: absolute;
  right: -16px;
  top: -16px;
}

.modal-header .d-flex {
  position: relative;
}

.football-container .matchHeader {
  margin-right: 0px;
}

.purchaseContainer.cardBack {
  padding: 56px 40px 56px 40px;
  border-radius: 24px;
  border: 1px solid var(--WHITE);
}

.modal-content .purchaseCardContainer .cardBack {
  height: 768px;
  max-width: 442px;
}

.modal-body .purchaseContainer div:nth-child(2) .cardBack .sideMain {
  margin-bottom: 6px;
}

.modal-dialog.modal-lg.modal-dialog-centered {
  max-width: inherit;
  align-items: center;
  justify-content: center;
}

#filter-d-flex {
  width: 100%;
  overflow: auto;
  display: -webkit-box;
  gap: 10px;
  scrollbar-width: none;
}

#filter-d-flex::-webkit-scrollbar {
  width: 0;
}

/* .matchCards-container{
  height: calc(100vh - 280px);
} */

.matchCards {
  /* height: 650px; */
  overflow: scroll;
}

.matchRightContainer div {
  padding-top: 5px;
}

#dateMainDiv .react-datepicker__month-container {
  padding: 8px 14px;
}

#dateMainDiv .react-datepicker__navigation {
  top: 5px;
}

.react-datepicker-popper {
  background: white;
}

#dateMainDiv .react-datepicker__week {
  padding: 14px 6px 0 6px;
}

#dateMainDiv .react-datepicker__day-names {
  padding: 0px 8px;
}

/* .react-datepicker {
  color: var(--PRIMARY) !important;
} */
.react-datepicker__day {
  color: var(--PRIMARY) !important;
}

.userContainer .react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  border-radius: none;
  position: absolute;
  top: 15px;
  right: 2px;
  font-size: var(--FONT20);
}

.userContainer
  #dateMainDiv
  .react-datepicker__input-container
  .react-datepicker__close-icon::after {
  color: var(--WHITE);
}

.react-datepicker__day--outside-month {
  color: var(--WHITE) !important;
  pointer-events: none;
}

.dateContainer .react-datepicker__day--outside-month:hover {
  border-radius: 99px;
  background-color: var(--WHITE);
  visibility: hidden;
  pointer-events: none;
}

.eventsLinksContainer #eventCard .subContainer {
  align-items: start;
}

.eventsLinksContainer h1 {
  font-weight: 800;
}

.eventsLinksContainer #eventCard div#subRightMain {
  align-items: end;
}

.eventsLinksContainer .subContainer {
  align-items: end;
}
#dateEventDiv {
  min-width: 87px;
  font-size: var(--FONT16);
  height: 38px;
  white-space: nowrap;
}

#dateEventDiv .dot {
  color: var(--SUBGREEN);
}

#eventCard .dot {
  color: var(--SUBORANGE);
}

.google-places-auto-complete .css-1nmdiq5-menu {
  z-index: 300 !important;
}

.subIcon {
  cursor: pointer;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.pagination-spinner {
  display: flex;
  align-items: center;
  /* width: 100vh; */
  height: 30vh;
  justify-content: center;
  margin-bottom: 72px;
}

.pagination-user{
  display: flex;
  align-items: center;
  width: 100vh;
  height: 30vh;
  justify-content: center;
  margin-bottom: 72px;
}

.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: var(--SECONDARY);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.eventsHeading .matchCard {
  padding: 24px;
}

.dashboardContainer .search-header {
  margin-bottom: 24px;
}

.selectedSideMain {
  color: var(--SECONDARY) !important;
}

.sideMain .selectedSideMain {
  color: var(--SECONDARY) !important;
}

#eventCard .leftSubContainer a {
  text-decoration: none;
  color: var(--PRIMARY);
}

.leftSubContainer .icon-link {
  padding-bottom: 2px;
}

.football-container .dashboardTitle {
  color: var(--SUBGREEN) !important;
}

.dashboardContainer .onboardingCard .cardSubHeader {
  height: auto;
  color: white;
  /* word-break: break-word; */
}

.total-events-item h3:nth-child(2) {
  font-family: var(--Faktum-Test);
  font-weight: bolder;
  font-size: var(--FONT32);
  margin-bottom: 0px;
}

.total-events-item h3:nth-child(3) {
  font-family: var(--Faktum-Test);
  font-weight: bolder;
  font-size: var(--FONT20);
  margin-bottom: 0px;
}

.disable-event h3:nth-child(2) {
  font-family: var(--Faktum-Test);
  font-weight: bolder;
  font-size: var(--FONT32);
  margin-bottom: 0px;
}

.disable-event h3:nth-child(3) {
  font-family: var(--Faktum-Test);
  font-weight: bolder;
  font-size: var(--FONT20);
  margin-bottom: 0px;
}

.adminDashboard .cardTitle {
  padding-right: 0px;
}

.locationDropdown .active-toggle .dropdown-option {
  width: 146px;
}

.locationDropdown .dropdown-option label {
  word-wrap: break-word;
}

/* .football-container .match-card-section .matchCard {
  max-width: 404px;
} */

.calendar-section .total-count h1 {
  padding-top: 10px;
}

.userContainer .locationDropdown .dropdown-option {
  max-width: 202px;
  max-height: 160px;
  overflow: auto;
  scrollbar-color: var(--PRIMARY) var(--SUBGRAY);
  scrollbar-width: thin;
}
.userContainer .locationDropdown .dropdown-option::-webkit-scrollbar {
  display: inherit;
  scrollbar-width: thin;
  height: 2px;
  border: 1px solid var(--PRIMARY);
}

.userContainer .locationDropdown .dropdown-option::-webkit-scrollbar-track {
  border-radius: 0;
  width: 1px;
  background: var(--SUBGRAY);
  height: 6px;
}

.userContainer .locationDropdown .dropdown-option::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: var(--PRIMARY);
  width: 1px;
  height: 2px;
}

.userContainer .locationDropdown button {
  max-width: 202px;
}

.react-datepicker__header.react-datepicker-year-header {
  border-bottom: 1px solid var(--SUBGRAY);
}

.offcanvas .react-datepicker__month-wrapper {
  display: flex;
  justify-content: center;
  gap: 30px;
}

/* .react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover, {
  background-color: var(--SUBGRAY);
  border-radius: 99px;
} */

.react-datepicker__month-text:hover {
  border-radius: 99px;
}

div#eventCard {
  max-width: 404px;
}
.adminDashboard .matchCard .matchRightContainer {
  position: relative;
  right: 0px;
  width: 80px;
  min-width: 80px;
}
.adminDashboard .matchCard .matchBody {
  max-width: 100% !important;
}
.userWrapper .eventsLinksContainer .hotelsSection .matchHeader {
  padding-top: 0px;
}
.selectedItem {
  border: 1px solid var(--SECONDARY);
}
.selectedDate {
  color: var(--SECONDARY) !important;
}
.userWrapper .filter-Subgroup div:nth-child(1) .active-toggle {
  letter-spacing: -0.2px;
}
.eventsLinksContainer .leftSubContainer {
  letter-spacing: 1px;
  width: auto;
  padding: 8px;
  font-size: var(--FONT16);
}
.user-dashboard .stickBox {
  padding-right: 64px;
}
.userContainer .active-toggle {
  font-family: var(--Faktum-Test);
  font-weight: var(--FONT_WEIGHT_800);
}
.userContainer .toggle-button {
  font-family: var(--Faktum-Test);
  font-weight: var(--FONT_WEIGHT_800);
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  fill: #ffffff;
  color: white;
  stroke: #aeaeae;
}
.customBtnDiv {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calender-main .disable-view h2 {
  font-family: var(--Faktum-Test);
  font-weight: bolder;
}
.calender-main .event-view h2 {
  font-family: var(--Faktum-Test);
  font-weight: bolder;
}
.calendar-item.selectedItem.disable-calendar-item.calendar-item.selectedItem:hover {
  color: var(--PRIMARY);
  border: 1px solid var(--WHITE);
}
.calendar-item.selectedItem.disable-calendar-item.calendar-item.selectedItem:hover
  .selectedDate {
  color: var(--PRIMARY) !important;
}
input#rememberMe {
  cursor: pointer;
}
.resizable-text {
  width: 200px; /* Define the width of the container */
  height: 100px; /* Define the height of the container */
  overflow: hidden; /* Hide overflowing text */
  border: 1px solid #ccc; /* Just for styling */
  padding: 10px; /* Add padding */
  position: relative; /* Position relative for inner elements */
}

.resizable-text p {
  margin: 0;
}
.otpSubTxt{
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
}
/*      media queries        */

@media (max-width: 1440px) {
  .userContainer .dateContainer .react-datepicker__navigation--previous {
    right: -13px !important;
  }
  .userContainer .dateContainer .react-datepicker__navigation--next {
    right: -43px;
  }

  .userContainer .locationDropdown button {
    max-width: 187px;
  }

  .userContainer .searchContainer input {
    width: 360px;
  }

  .userContainer .searchContainer .filterBtn {
    padding: 16px 18px;
  }

  .userContainer .extra-class {
    padding: 16px 18px !important;
  }

  .match-card-section .matchCard {
    min-width: 385px;
  }
  .events-details-container .match-card-section .matchCard {
    min-width: auto !important;
  }
  /* .football-container .match-card-section .matchCard {
    max-width: 404px;
  } */

  .calendar-section .title-bar {
    gap: 16px;
    font-size: 24px;
    justify-content: flex-start;
    align-items: center;
  }

  .calendar-section .title-bar h1 {
    font-size: 24px;
  }

  .btn-filter {
    height: 40px;
    border: none;
    font-size: 14px;
    padding: 9px 16px;
  }

  .calendar-section .total-count {
    text-align: end;
    font-weight: var(--FONT_WEIGHT_800);
    word-wrap: break-word;
  }

  .calendar-section .total-count h1 {
    font-size: 20px;
  }

  .toggle-button {
    /* width: auto; */
    width: -webkit-fill-available;
  }
  .userContainer .locationDropdown .dropdown-option {
    width: 126px;
  }
  .active-toggle {
    /* width: auto; */
    width: -webkit-fill-available;
  }



  .userContainer .filter-group button {
    min-width: 126px;
  }

  .userContainer {
    padding: 30px;
  }

  .userWrapper .dashboardDiv {
    padding-bottom: 0px;
  }

  .userWrapper .onboardingCard {
    height: 248px;
    width: 227px;
  }
}

@media (max-width: 767px) {
  .icon-Travel{
    display: none;
  }
  .onboardingCard .matchRightContainer span {
    font-size: var(--FONT20);
    font-weight: var(--FONT_WEIGHT_800);
}
  .onboardImg{
    width: 350px;
  }
  .eventsLinksContainer .matchRightContainer div {
    padding-top: 5px;
    font-size: var(--FONT28);
  }
  .eventsLinksContainer .matchRightContainer span {
    font-size: var(--FONT20);
  }
  .user-dashboard .stickBox {
    padding-right: 16px;
  }
  .dashboardContainer .onboardingCard .cardSubHeader span {
    letter-spacing: 0.1px;
    text-wrap: nowrap;
  }
  .eventsLinksContainer .match-container {
    padding-bottom: 32px;
  }
  .events-details-container {
    padding: 0 16px;
  }

  .userWrapper .events-container .headerContainer {
    height: auto !important;
  }

  .calendar-section .total-count h1 {
    font-size: var(--FONT16);
    font-family: var(--Faktum-Test);
    font-weight: bolder;
    color: var(--SECONDARY);
    text-align: end;
  }
  .stickBox {
    padding-bottom: 50px;
  }
  .events-container .stickBox {
    padding-bottom: 24px;
  }
  .userWrapper .matchCards {
    max-height: fit-content !important;
    margin-bottom: 72px;
    margin-right: 16px;
  }

  .adminDashboard .matchCards {
    margin-bottom: 72px;
  }

  .events-container {
    padding: 0px 16px;
  }

  .dashboardContainer .search-header {
    margin-right: 16px;
    margin-top: 24px;
  }

  /* .userWrapper .dashboardCards{
    height: calc(100vh - 150px);
  } */
  .purchaseCardContainer span {
    color: var(--SECONDARY);
    font-size: var(--FONT20);
  }

  .userWrapper .onboardingCards img {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 19.6px;
  }

  .userWrapper .onboardingCards .leftSubContainer {
    color: var(--WHITE);
    position: absolute;
    bottom: 24px;
    left: 18px;
    width: 107px;
    height: 28px;
    padding: 3px 6px;
    gap: 6px;
    letter-spacing: -0.5px;
  }

  .userWrapper .onboardingCard {
    display: flex;
    flex-direction: column;
    max-height: 248px;
    width: 227px;
    overflow: hidden;
    position: relative;
  }

  .userWrapper .onboardingCards .leftSubContainer {
    color: var(--WHITE);
    position: absolute;
    bottom: 25px;
    left: 23px;
    width: 107px;
  }

  .userWrapper .onboardingCards .matchRightContainer {
    position: absolute;
    bottom: 25px;
    right: 23px;
  }

  .modal-content {
    padding: 32px 16px 76px 16px;
  }

  .settingContainer {
    padding-bottom: 64px;
  }

  .userContainer .react-datepicker-popper {
    left: 58px !important;
  }

  .userContainer {
    padding: 32px 16px 0px 16px;
  }

  .authPages {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--PRIMARY);
    min-height: 100vh;
    padding: 0;
  }

  .onboardingCards .matchRightContainer {
    width: 64px;
    height: 64px;
    right: 10px;
    font-size: 26px;
    padding-top: 7px;
  }

  .football-container .subContent {
    padding-right: 0px !important;
  }

  .modal-header {
    padding: 16px 0px;
    font-size: var(--FONT18);
  }

  .modal-header span {
    position: absolute;
    right: -9px;
    top: -16px;
    font-size: var(--FONT16);
  }

  .purchaseCardContainer p {
    max-width: 376px;
  }

  .purchaseCardContainer .cardBack {
    height: auto;
  }

  .purchaseContainer .cardBack {
    padding: 16px;
  }

  .userWrapper .dashboardDiv .custom-btn {
    padding: 12px 11px;
    font-size: var(--FONT16);
    letter-spacing: -1px;
  }

  .userWrapper .dashboardContainer {
    min-height: 100vh;
    padding: 64px 0 0 16px;
    margin-bottom: 72px;
  }

  .userWrapper .subContent {
    padding-right: 16px;
  }

  .userWrapper #subHeader {
    border-bottom: 1px solid var(--SECONDARY);
    margin-right: 16px;
  }

  .userWrapper .matchHeader {
    margin-right: 16px;
  }

  .userWrapper .eventsLinksContainer .matchHeader {
    margin-right: 0px;
  }

  .userWrapper .matchCards {
    margin-right: 16px;
  }

  .userWrapper #subHeader span {
    color: var(--WHITE);
  }

  .userWrapper .matchCards {
    max-height: fit-content !important;
  }

  .userWrapper .onboardingCards {
    margin-right: 16px;
  }

  .filter-Subgroup {
    display: none !important;
  }

  .offcanvas-backdrop.show {
    background-color: var(--BLACK);
    opacity: 0.7;
  }

  .eventsLinksContainer {
    padding: 64px 16px 0 16px;
  }

  .eventsLinksContainer .eventsHeading .matchTitle {
    font-size: var(--FONT32) !important;
  }
   .word-correct-spacing{
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    text-transform: uppercase;
    word-break: inherit;
  }

  .subContainer .eventLeftContainer {
    font-size: var(--FONT16);
    gap: 3px;
    width: fit-content;
  }

  .eventsLinksContainer .eventsHeading .matchSubTitle {
    font-size: var(--FONT20);
    margin-top: 6px;
  }

  .eventsHeading .subContainer p {
    font-size: var(--FONT18);
    margin-bottom: 22px;
    margin-top: 16px;
  }

  .eventsHeading .matchBody {
    gap: 0px;
  }

  .eventSubContainer {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }

  .userWrapper .filter-Subgroup div button {
    width: 138px !important;
    padding: 11px 6px !important;
    font-size: var(--FONT10) !important;
    position: relative;
  }

  .userContainer .searchContainer input {
    width: 200px;
  }

  .userWrapper .dropdown-option {
    position: absolute;
    width: 138px !important;
  }

  .onboardingCard .cardHeader div {
    width: fit-content;
  }

  .onboardMain .onboardingCards {
    display: flex;
    gap: 0px !important;
    margin-left: 17px;
  }

  .onboardMain .onboardingCard {
    gap: 0;
    padding: 11px;
    border-radius: 6px;
    padding: 6px;
  }

  .onboardMain .onboardingCards .leftSubContainer {
    width: 39.5px;
    font-size: var(--FONT5);
    padding: 1px 4px !important;
    height: 10px;
    padding: 6.354px;
    gap: 2.943px;
    left: 8px;
    bottom: 9px;
    letter-spacing: -0.25px;
  }

  .onboardMain .onboardingCards .leftSubContainer span {
    font-size: var(--FONT4);
  }

  .onboardMain .onboardingCards .matchRightContainer {
    border-radius: 47.5px;
    font-size: var(--FONT12);
    width: 23px;
    height: 23px;
    right: 8px;
    bottom: 9px;
    padding: 4.863px;
  }

  .onboardMain .onboardingCards .matchRightContainer span {
    font-size: var(--FONT8);
    top: -5px;
  }

  .onboardMain .onboardingCard .cardHeader div {
    height: 7px;
    width: fit-content;
    padding: 2px 1px !important;
    border-radius: 3px;
    background-color: var(--WHITE);
    font-size: var(--FONT4);
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .onboardMain .onboardingCards img {
    width: 70px;
    height: 58px;
    border-radius: 7px;
  }

  .onboardMain .onboardingCard .cardHeader h1 {
    font-size: var(--FONT10);
    letter-spacing: -0.5px;
  }

  .onboardMain .onboardingCards .cardSubHeader h6 {
    font-size: var(--FONT5);
  }

  .onboardMain .onboardingCards .cardSubHeader span {
    font-size: var(--FONT3);
    letter-spacing: 0.2px;
    padding-top: 2px;
  }

  .onboardMain .onboardingCard .cardSubHeader {
    height: auto;
    margin-bottom: 0px;
  }

  .onboardMain .onboardingCards .onboardingCard {
    margin-left: -17px;
  }

  .userWrapper .dashboardTitle {
    padding-top: 0px;
    letter-spacing: -2.5px;
  }

  .userWrapper .main-content {
    margin: 0px;
  }

  .userWrapper .sidebar {
    width: 100%;
  }

  .userWrapper .sidebar .logOut {
    display: none;
  }

  .whiteBack {
    border: none;
  }

  .authCenter {
    width: 100%;
  }
  .blur-effect {
    filter: blur(1px);
  }

  .purchaseContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* transform: scale(0.78);
    margin: -232px 0; */
    gap: 8px;
  }

  .onboardingContainer {
    margin: 40px 20px;
  }

  .purchaseCardContainer {
    font-size: 14px;
  }

  .stepMain {
    height: 414px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .step4Container .secondaryBack {
    position: absolute;
    bottom: 12px;
    right: 75px;
    height: 41px;
  }

  .step4Container .primaryBack {
    position: absolute;
    top: 49px;
    left: 93px;
    width: max-content;
    height: 41px;
  }

  .howItWorks .subContainer {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    height: 100%;
  }

  .howItWorks .icon-arrow {
    display: none;
  }

  .onboardingCards {
    display: flex;
  }

  .dashboardContainer {
    padding: 64px 16px 0 16px;
  }

  .dashboardTitle {
    padding: 0 0 24px 0;
    font-size: var(--FONT48);
  }

  .dashboardDescription {
    padding-bottom: 8px;
  }

  .cardContainer h1 {
    font-size: var(--FONT32);
  }

  .sidebar nav a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: var(--FONT13);
    font-weight: var(--FONT_WEIGHT_400);
    color: var(--WHITE);
    flex-direction: column;
    padding: 0px 0px;
    width: fit-content;
    border-radius: 10px;
  }

  .sidebar nav a span {
    margin-bottom: 0px;
    padding: 8px;
  }

  .sidebarMenu p {
    display: none;
  }

  .main-content {
    position: relative;
    margin-left: 0px;
  }

  .sidebarMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .sidebarMenu {
    padding: 0px 0px;
  }

  .subCards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .cardTitle {
    font-size: var(--FONT14);
    letter-spacing: -1px;
  }

  .matchCards {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    max-height: fit-content !important;
  }

  .matchCard {
    padding: 16px;
  }

  .matchHeader {
    font-size: var(--FONT24);
    padding-top: 48px;
    margin-bottom: 24px;
  }

  .matchTitle {
    font-size: var(--FONT24);
  }

  .sidebar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    font-weight: var(--FONT_WEIGHT_800);
    background-color: var(--PRIMARY);
    z-index: 100;
    height: fit-content;
    border: none;
    padding: 16px 28px;
  }

  .dashboardLogo {
    display: none;
  }

  .logOut {
    display: none;
  }

  .userWrapper .searchContainer {
    width: 100%;
    display: flex;
  }

  .userWrapper .searchBtn {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .userWrapper .buttonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
  }

  .userWrapper .d-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .userWrapper .filter-Subgroup div button {
    width: 145px;
    padding: 12px 8px;
    font-size: var(--FONT11);
    position: relative;
  }

  .userWrapper .dropdown-option label {
    font-size: var(--FONT11);
  }

  .userWrapper .dropdown-option label input {
    font-size: var(--FONT11);
  }

  .onboardingCards {
    display: flex;
    gap: 8px !important;
  }

  .onboardingCard {
    padding: 14.8px;
  }

  .onboardingCards img {
    width: 198px;
    height: 158px;
  }
  .multipleOptionActive{
    display: flex;
    align-items:normal;
  }
  .onboardingCard .cardHeader h1 {
    font-size: var(--FONT28);
    letter-spacing: -0.61;
  }

  .onboardingCards .cardSubHeader h6 {
    font-size: var(--FONT14);
  }

  .onboardingCards .cardSubHeader span {
    font-size: var(--FONT8);
  }

  .onboardingCard .cardSubHeader {
    height: 26px;
  }

  .imageContainer .leftSubContainer {
    font-size: var(--FONT14);
    padding: 3px 11px;
  }

  .imageContainer span {
    font-size: var(--FONT10);
  }

  .imageContainer .matchRightContainer span {
    font-size: var(--FONT22);
  }

  .stepSubTitle {
    width: auto;
  }

  .eventsHeading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .eventsLinksContainer #eventsHeader .matchCard {
    width: 100%;
  }

  .hotelsSection {
    padding-bottom: 132px;
  }

  #eventsHeader .icon-Travel {
    margin-top: 32px;
  }

  .eventsLinksContainer .matchHeader {
    font-size: var(--FONT24);
    padding-top: 48px;
    margin-bottom: 24px;
  }

  .modal-content .purchaseContainer {
    transform: scale(1);
    margin: 0;
  }

  /* .onboardingCard .cardHeader div {
    font-size: var(--FONT8);
  } */

  .onboardingCard .cardHeader div:nth-child(2) {
      background-color: var(--WHITE);
      font-size: var(--FONT8);
      min-width: auto;
      font-weight: var(--FONT_WEIGHT_800);
  }
  .userWrapper .headerContainer {
    padding-top: 16px;
    margin-bottom: 8px !important;
    height: 110px;
  }

  .football-container {
    padding: 0px 16px 72px 16px;
  }

  .onboardMain {
    padding: 32px 16px;
  }

  .subTitleContainer p {
    margin-bottom: 0;
    text-align: center;
    max-width: auto;
    padding: 0;
  }

  .modal-content .purchaseCardContainer .cardBack {
    height: auto;
    padding-bottom: 20px;
  }

  .modal-body {
    padding: 0px;
  }

  .events-details-container {
    padding: 0px 16px;
  }

  .events-details-container .match-container {
    margin-bottom: 72px;
  }

  .calender-main {
    margin-bottom: 72px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .onboardImg{
    width: 720px;
  }
  .user-dashboard .stickBox {
    padding-right: 16px;
  }
  .onboardingCard .cardSubHeader span {
    letter-spacing: 0.1px;
    text-wrap: nowrap;
  }
  .eventsLinksContainer .match-container {
    padding-bottom: 32px;
  }
  .calendar-section .total-count h1 {
    font-size: var(--FONT16);
    font-family: var(--Faktum-Test);
    font-weight: bolder;
    color: var(--SECONDARY);
    text-align: end;
  }

  .events-details-container {
    padding: 0 16px;
  }

  .multipleOptionActive{
    display: flex;
    align-items:normal;
  }

  .events-container {
    padding: 0px 16px;
  }

  .userWrapper .onboardingCard {
    height: 248px;
    width: 227px;
  }

  .dashboardContainer .search-header {
    margin-right: 16px;
  }

  .eventsLinksContainer {
    padding: 64px 16px 0 16px;
  }

  .userWrapper .onboardingCards .leftSubContainer {
    color: var(--WHITE);
    position: absolute;
    bottom: 24px;
    left: 18px;
    width: 107px;
    height: 28px;
    padding: 3px 6px;
    gap: 6px;
    letter-spacing: -0.5px;
  }

  .userWrapper .onboardingCards .matchRightContainer {
    position: absolute;
    bottom: 24px;
    right: 18px;
  }

  .userContainer {
    padding: 32px 16px 0px 16px;
  }

  .userContainer .react-datepicker-popper {
    left: 58px !important;
  }

  .onboardingCards .matchRightContainer {
    width: 64px;
    height: 64px;
    right: 10px;
    font-size: 26px;
    padding-top: 7px;
  }

  .userWrapper .dashboardDiv {
    padding-bottom: 0px;
  }

  .modal-content .purchaseCardContainer .cardBack {
    height: auto;
    max-width: 442px;
    padding-bottom: 20px;
  }

  .modal-content {
    background-color: var(--PRIMARY);
    width: auto;
    padding: 32px 32px 40px 32px;
  }

  .purchaseContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    justify-content: center;
    align-items: center;
    transform: scale(0.78);
    margin: -166px -100px;
  }

  .modal-body .purchaseContainer {
    margin: -71px -100px;
    gap: 16px;
  }

  .purchaseContainer div:nth-child(3) .cardBack {
    margin-top: 16px;
  }

  .purchaseCardContainer .cardBack {
    height: auto;
  }

  .purchaseContainer .cardBack {
    padding: 16px;
  }

  .purchaseCardContainer p {
    margin-bottom: 12px;
    width: 375px;
  }

  .subTitleContainer p {
    margin-bottom: 0;
    text-align: center;
    max-width: auto;
    padding: 0;
  }

  .football-container {
    padding: 0px 16px 84px 16px;
  }

  .userWrapper .dashboardDiv .custom-btn {
    padding: 12px 11px;
    font-size: var(--FONT16);
    letter-spacing: -1px;
  }

  .userWrapper .dashboardContainer {
    min-height: 100vh;
    padding: 64px 0 0 16px;
  }

  .userWrapper .subContent {
    padding-right: 16px;
  }

  .userWrapper #subHeader {
    border-bottom: 1px solid var(--SECONDARY);
    margin-right: 16px;
  }

  .football-container #subHeader {
    margin-right: 0px;
  }

  .football-container .matchHeader {
    margin-right: 0px;
  }

  .userWrapper .matchHeader {
    padding-top: 96px;
    margin-right: 16px;
  }

  .userWrapper .headerContainer {
    padding-top: 16px;
    margin-bottom: 8px !important;
  }

  .userWrapper .matchCards {
    max-height: fit-content !important;
    margin-bottom: 72px;
    margin-right: 16px;
  }

  .adminDashboard .matchCards {
    margin-bottom: 72px;
  }

  .userWrapper .onboardingCards {
    margin-right: 16px;
  }

  .onboardingCard .cardHeader div {
    font-size: var(--FONT8);
  }

  .filter-Subgroup {
    display: none !important;
  }

  .hotelsSection {
    padding-bottom: 132px;
  }

  .userContainer .searchContainer input {
    width: 300px;
  }

  .userContainer .searchContainer .filterBtn {
    padding: 16px 10px;
  }

  .userContainer .searchContainer .extra-class {
    padding: 16px 10px !important;
  }

  .userWrapper .dashboardTitle {
    padding-top: 0px;
  }

  .userWrapper .sidebar .logOut {
    display: none;
  }

  .userWrapper .sidebar {
    width: 100%;
  }

  .userWrapper .main-content {
    margin-left: 0px;
  }

  .onboardingCards {
    display: flex;
    /* transform: scale(0.61);
    margin: -60px 0; */
  }

  .dashboardLogo {
    display: none;
  }

  .logOut {
    display: none;
  }

  .sidebar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    font-weight: var(--FONT_WEIGHT_800);
    background-color: var(--PRIMARY);
    z-index: 100;
    height: fit-content;
    border: none;
    padding: 16px 28px;
  }

  .dashboardContainer {
    padding: 64px 16px 0 16px;
  }

  .dashboardTitle {
    padding: 0 0 24px 0;
    font-size: var(--FONT48);
  }

  .dashboardDescription {
    padding-bottom: 8px;
  }

  .cardContainer h1 {
    font-size: var(--FONT32);
  }

  .sidebar nav a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: var(--FONT13);
    font-weight: var(--FONT_WEIGHT_400);
    color: var(--WHITE);
    flex-direction: column;
    padding: 0px 0px;
    width: fit-content;
    border-radius: 10px;
  }

  .sidebar nav a span {
    margin-bottom: 0px;
    padding: 8px;
  }

  .sidebarMenu p {
    display: none;
  }

  .main-content {
    position: relative;
    margin-left: 0px;
  }

  .sidebarMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .sidebarMenu {
    padding: 0px 0px;
  }

  .subCards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .cardTitle {
    font-size: var(--FONT14);
    letter-spacing: -1px;
  }

  .matchHeader {
    font-size: var(--FONT24);
    margin-bottom: 24px;
  }

  .matchTitle {
    font-size: var(--FONT24);
  }

  .matchCard {
    padding: 16px;
  }

  .matchCards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    overflow: auto;
  }

  /* .matchCards .matchCard:nth-last-child(2) {
    margin-bottom: 98px;
  } */
  .cardContainer p {
    font-size: var(--FONT12);
  }

  .cardContainer h1 {
    font-size: var(--FONT32);
  }

  .subCards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .howItWorks .subContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 561px;
    height: 100%;
  }

  .howItWorks .icon-arrow {
    display: none;
  }

  .step2Container .stepSubTitle p {
    margin-bottom: 0px;
  }

  .step4Container .primaryBack {
    position: absolute;
    top: 49px;
    left: 89px;
    width: max-content;
    height: 41px;
  }

  .step4Container .secondaryBack {
    position: absolute;
    bottom: 12px;
    right: 77px;
    height: 42px;
  }

  .howItWorks .subContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 561px;
    gap: 56px;
    height: 100%;
    row-gap: 8px;
  }

  .stepMain {
    height: 451px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .userWrapper .searchContainer {
    width: 100%;
    display: flex;
  }

  .userWrapper .searchBtn {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .userWrapper .buttonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
  }

  .userWrapper #subHeader {
    border-bottom: 1px solid var(--WHITE);
    color: var(--WHITE);
  }

  .userWrapper #subHeader span {
    color: var(--WHITE);
  }

  .userWrapper .d-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .onboardingCards {
    display: flex;
    gap: 8px !important;
  }

  .onboardingCard {
    padding: 14.8px;
  }

  .userWrapper .onboardingCard {
    display: flex;
    flex-direction: column;
    max-height: 248px;
    width: 227px;
    overflow: hidden;
    position: relative;
  }

  .onboardingCards img {
    width: 198px;
    height: 158px;
  }

  .userWrapper .onboardingCards img {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 19.6px;
  }

  .onboardingCard .cardHeader h1 {
    font-size: var(--FONT28);
    letter-spacing: -0.61;
  }

  .onboardingCards .cardSubHeader h6 {
    font-size: var(--FONT14);
  }

  .onboardingCards .cardSubHeader span {
    font-size: var(--FONT9);
  }

  .onboardingCard .cardSubHeader {
    height: 26px;
  }

  .imageContainer .leftSubContainer {
    font-size: var(--FONT14);
    padding: 3px 11px;
  }

  .imageContainer span {
    font-size: var(--FONT10);
  }

  .imageContainer .matchRightContainer span {
    font-size: var(--FONT18);
  }

  .eventsLinksContainer #eventsHeader .matchCard {
    width: 70%;
    flex: none;
  }

  .offcanvas {
    --bs-offcanvas-width: 45%;
  }

  .settingContainer {
    padding-bottom: 64px;
  }

  .events-details-container .match-container {
    margin-bottom: 72px;
  }

  .calender-main {
    margin-bottom: 72px;
  }
}

@media (min-width: 1025px) and (max-width: 1279px) {
  .onboardImg{
    width:920px;
  }
  .calendar-section .total-count h1 {
    font-size: var(--FONT20);
    font-family: var(--Faktum-Test);
    font-weight: bolder;
    color: var(--SECONDARY);
    text-align: end;
  }

  .adminDashboard {
    padding: 64px 32px 0px 32px;
  }

  .userWrapper .onboardingCard {
    height: 321px;
    width: 289px;
  }

  .userWrapper .onboardingCards .leftSubContainer {
    color: var(--WHITE);
    position: absolute;
    bottom: 44px;
    left: 28px;
  }

  .userWrapper .onboardingCards .matchRightContainer {
    position: absolute;
    bottom: 44px;
    right: 28px;
  }

  .userWrapper .headerContainer {
    padding-top: 24px;
    /* height: 260px !important; */
  }

  .userContainer .react-datepicker-popper {
    left: 58px !important;
  }

  .eventsLinksContainer #eventsHeader .matchCard {
    width: 60%;
    flex: none;
  }

  .userContainer .searchContainer input {
    width: 320px;
  }

  .userWrapper .d-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .userWrapper .filterBtn {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  .onboardingCards {
    display: flex;
  }

  .step2Container .stepSubTitle p {
    margin-bottom: 0px;
  }

  .step4Container .primaryBack {
    position: absolute;
    top: 49px;
    left: 89px;
    width: max-content;
    height: 41px;
  }

  .step4Container .secondaryBack {
    position: absolute;
    bottom: 12px;
    right: 77px;
    height: 42px;
  }

  .howItWorks .subContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 561px;
    gap: 56px;
    height: 100%;
    row-gap: 8px;
  }

  .howItWorks .icon-arrow {
    display: none;
  }

  .howItWorks .subContainer .onboardingCards {
    display: flex;
  }

  .stepMain {
    height: 451px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .matchCards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    overflow: auto;
  }

  .cardContainer h1 {
    font-size: var(--FONT40);
  }

  .cardContainer p {
    font-size: var(--FONT16);
  }

  .subCards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .purchaseContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    transform: scale(0.8);
    margin: -152px 0;
  }

  .modal-content .purchaseContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    transform: scale(0.8);
    margin: -71px 0 !important;
  }

  .userWrapper .searchContainer {
    width: 100%;
    display: flex;
  }

  .userWrapper .searchBtn {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .userWrapper .buttonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
  }

  .onboardingCards {
    display: flex;
    gap: 12.7px;
  }

  .onboardingCard {
    padding: 20px;
  }

  .onboardingCards img {
    width: 251px;
    height: 209px;
  }

  .onboardingCard .cardHeader h1 {
    font-size: var(--FONT32);
  }

  .onboardingCards .cardSubHeader h6 {
    font-size: var(--FONT18);
  }

  .onboardingCards .cardSubHeader span {
    font-size: var(--FONT10);
  }

  .offcanvas {
    display: none;
  }
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .adminDashboard .matchCard .matchTitle {
    max-width: 180px;
  }
  .purchaseContainer {
    gap: 24px;
  }
  .cardBack {
    padding: 56px 32px 56px 32px;
  }
  .calendar-section .total-count h1 {
    font-size: var(--FONT20);
    font-family: var(--Faktum-Test);
    font-weight: bolder;
    color: var(--SECONDARY);
    text-align: end;
  }

  .adminDashboard {
    padding: 64px 32px 0px 32px;
  }

  .userWrapper .onboardingCard {
    height: 321px;
  }

  .onboardMain .onboardingCard .cardHeader h1 {
    font-size: var(--FONT38);
  }

  .onboardMain .onboardingCard {
    padding: 19px;
  }

  .userWrapper .onboardingCard {
    max-height: 321px;
    width: 289px;
  }

  .userWrapper .onboardingCards .leftSubContainer {
    color: var(--WHITE);
    position: absolute;
    bottom: 40px;
    left: 28px;
  }

  .userWrapper .onboardingCards .matchRightContainer {
    position: absolute;
    bottom: 40px;
    right: 28px;
  }

  .userContainer .react-datepicker-popper {
    left: 58px !important;
  }

  .purchaseContainer {
    display: flex;
    justify-content: center;
    transform: scale(0.8);
    margin: -152px 0;
  }

  .purchaseCardContainer .cardBack {
    width: 440px;
  }

  .userWrapper .searchFilter.d-flex input {
    width: 802px;
  }

  .userWrapper .searchFilter.d-flex {
    width: auto;
  }

  .onboardingCards {
    display: flex;
    gap: 12.7px;
  }

  .onboardingCard {
    padding: 20px;
  }

  .userWrapper .onboardingCards img {
    width: 251px;
    height: 209px;
  }

  .onboardingCard .cardHeader h1 {
    font-size: var(--FONT32);
  }

  .onboardingCards .cardSubHeader h6 {
    font-size: var(--FONT18);
  }

  .onboardingCards .cardSubHeader span {
    font-size: var(--FONT12);
  }

  .matchCards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    overflow: auto;
  }

  .cardContainer h1 {
    font-size: var(--FONT40);
  }

  .cardContainer p {
    font-size: var(--FONT16);
  }

  .subCards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .howItWorks {
    width: 1024px;
  }

  .purchaseContainer {
    transform: scale(0.8);
    margin: -71px 0;
  }

  .offcanvas {
    display: none;
  }

  .onboardMain .onboardingCards {
    display: flex;
    gap: 0px;
  }

  .onboardMain .onboardingCard {
    padding: 19px;
  }

  .onboardMain .onboardingCards img {
    width: 247px;
    height: 201px;
  }

  .onboardMain .onboardingCard .cardHeader h1 {
    font-size: var(--FONT38);
  }

  .onboardMain .onboardingCards .cardSubHeader h6 {
    font-size: var(--FONT18);
  }

  .onboardMain .onboardingCards .cardSubHeader span {
    font-size: var(--FONT10);
  }

  .onboardMain .onboardingCard .cardSubHeader {
    height: 27px;
  }
}

@media (min-width: 768px) and (max-width: 1320px) {
  .onboardMain .onboardingCards img {
    width: 150px;
    height: 122px;
  }

  .onboardMain .onboardingCards {
    display: flex;
    gap: 0px !important;
    margin-left: 33px;
  }

  .onboardMain .onboardingCard {
    padding: 11px;
  }

  .onboardMain .onboardingCards .matchRightContainer {
    border-radius: 47.5px;
    font-size: var(--FONT26);
    width: 48.5px;
    height: 48.5px;
    right: 17px;
    bottom: 19px;
  }

  .onboardMain .onboardingCards .matchRightContainer span {
    font-size: var(--FONT16);
    top: -8px;
  }

  .onboardMain .onboardingCard .cardHeader h1 {
    font-size: var(--FONT22);
  }

  .onboardMain .onboardingCards .cardSubHeader h6 {
    font-size: var(--FONT11);
  }

  .onboardMain .onboardingCards .cardSubHeader span {
    font-size: var(--FONT6);
  }

  .onboardMain .onboardingCard .cardSubHeader {
    height: 17px;
  }

  .onboardMain .onboardingCards .onboardingCard {
    margin-left: -28px;
    gap: 0;
  }

  .onboardMain .onboardingCard .cardHeader div {
    padding: 3px 6px;
    border-radius: 9px;
    background-color: var(--WHITE);
    gap: 8px;
    width: fit-content;
    height: max-content;
    font-size: var(--FONT8);
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .onboardMain .onboardingCards .leftSubContainer {
    font-size: var(--FONT11);
    padding: 2px 8px;
    height: 22px;
    padding: 6.354px;
    gap: 4.943px;
    left: 17px;
    bottom: 19px;
    width: 83px;
  }
}

@media (max-width: 1440px) {
  .calendar-item-container {
    grid-template-columns: repeat(auto-fill, minmax(164px, 1fr));
  }
}

@media (min-width: 1380px) and (max-width: 1440px) {
  .offcanvas {
    display: none;
  }

  .userContainer .react-datepicker-popper {
    left: 58px !important;
  }
}

@media (min-width: 1439px) and (max-width: 1600px) {
  .purchaseContainer {
    gap: 24px;
  }
  .cardBack {
    padding: 56px 32px 56px 32px;
  }
  .calendar-section .total-count h1 {
    font-size: var(--FONT20);
    font-family: var(--Faktum-Test);
    font-weight: bolder;
    color: var(--SECONDARY);
    text-align: end;
  }

  .userWrapper .onboardingCard {
    height: 404px;
  }

  .userWrapper .onboardingCards .leftSubContainer {
    color: var(--WHITE);
    position: absolute;
    bottom: 50px;
    left: 35px;
    width: 175px;
    font-size: var(--FONT24);
    padding: 6px 19px;
    height: 43px;
    border-radius: 18px;
  }

  .matchCards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    overflow: auto;
  }

  .cardContainer h1 {
    font-size: var(--FONT40);
  }

  .cardContainer p {
    font-size: var(--FONT16);
  }

  .howItWorks {
    width: 1260px;
  }

  .purchaseContainer {
    transform: scale(0.9);
    margin-top: -36px;
  }
  .purchaseContainer {
    gap: 18px;
}
  .onboardMain .onboardingCards {
    display: flex;
    gap: 0px;
  }

  .onboardMain .onboardingCard {
    padding: 19px;
  }

  .onboardMain .onboardingCards img {
    width: 247px;
    height: 201px;
  }

  .onboardMain .onboardingCard .cardHeader h1 {
    font-size: var(--FONT38);
  }

  .onboardMain .onboardingCards .cardSubHeader h6 {
    font-size: var(--FONT18);
  }

  .onboardMain .onboardingCards .cardSubHeader span {
    font-size: var(--FONT10);
  }

  .onboardMain .onboardingCard .cardSubHeader {
    height: 27px;
  }

  .offcanvas {
    display: none;
  }

  .userWrapper .searchFilter.d-flex input {
    width: 802px;
  }

  .userWrapper .searchFilter.d-flex {
    width: auto;
  }
}

@media (min-width: 1599px) {
  .calendar-section .total-count h1 {
    font-size: var(--FONT20);
    font-family: var(--Faktum-Test);
    font-weight: bolder;
    color: var(--SECONDARY);
    text-align: end;
    word-break: break-word;
  }

  .userWrapper .onboardingCard {
    height: 404px;
  }

  .onboardMain .onboardingCards .matchRightContainer {
    padding-top: 10px;
  }

  .userWrapper .onboardingCards .leftSubContainer {
    color: var(--WHITE);
    position: absolute;
    bottom: 50px;
    left: 35px;
    width: 175px;
    font-size: var(--FONT24);
    padding: 6px 19px;
    height: 43px;
    border-radius: 18px;
  }

  td.subContainer {
    max-width: 164px !important;
  }

  .modal-header span {
    position: absolute;
    right: -60px;
    top: -24px;
  }

  .modal-content {
    padding: 32px 86px 76px 86px;
  }

  .offcanvas {
    display: none;
  }

  .matchCards {
    overflow: auto;
  }

  .howItWorks {
    width: 1260px;
  }

  .onboardMain .onboardingCards {
    display: flex;
    gap: 0px;
  }

  .onboardMain .onboardingCard {
    padding: 19px;
  }

  .onboardMain .onboardingCards img {
    width: 247px;
    height: 201px;
  }

  .onboardMain .onboardingCard .cardHeader h1 {
    font-size: var(--FONT38);
  }

  .onboardMain .onboardingCards .cardSubHeader h6 {
    font-size: var(--FONT18);
  }

  .onboardMain .onboardingCards .cardSubHeader span {
    font-size: var(--FONT10);
  }

  .onboardMain .onboardingCard .cardSubHeader {
    height: 27px;
  }

  .userWrapper .searchFilter.d-flex input {
    width: 802px;
  }

  .userWrapper .searchFilter.d-flex {
    width: auto;
  }
  .matchCards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    scrollbar-width: none;
}
}
@media (min-width:1599px) and (max-width:1740px) {
  .matchCards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    scrollbar-width: none;
}
}
@media(min-width:1740px){
  .matchCards {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 20px;
    scrollbar-width: none;
}
}
/* -- for mobile devices css ---- */
@media (max-width: 490px) {
  .calendar-section .total-count h1 {
    font-size: var(--FONT14);
}
  .calender-month {
    font-size: var(--FONT24) !important;
    font-weight: var(--FONT_WEIGHT_800) !important;
    margin-right: 24px !important;
    min-width: auto;
}
  .football-card {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .event-details-card {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  div#eventCard {
    max-width: 100%;
  }
  .onboardMain .onboardingCard .cardHeader h1 {
    font-size: var(--FONT8);
    letter-spacing: -0.5px;
  }
  .onboardMain .onboardingCards .cardSubHeader h6 {
    font-size: var(--FONT4);
  }
  .onboardMain .onboardingCards .cardSubHeader span {
    font-size: var(--FONT2);
    letter-spacing: -0.1px;
    padding-top: 2px;
  }
}

@media (max-width: 430px) {
  .onboardImg{
    width: 330px;
  }
  div#eventCard {
    max-width: auto;
    min-width: auto;
    width: 100%;
  }

  .match-card-section {
    display: flex;
    gap: 20px;
    flex-direction: column;
    /* margin-bottom: 72px; */
  }

  .match-card-section .matchCard {
    min-width: 310px;
  }
  .swiper-wrapper {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
}

@media (max-width: 375px) {
  .onboardImg{
    width: 300px;
  }
  .football-container {
    padding: 0 16px;
    margin-bottom: 88px;
  }

  .calendar-section .total-count {
    text-align: end;
    white-space: normal;
    font-weight: var(--FONT_WEIGHT_800);
  }
  .calendar-section .total-count h1 {
    padding: 0px;
    margin: 0px;
  }
  .eventsHeading .matchCard {
    padding: 12px;
  }
  .football-container .matchHeader {
    padding-top: 0px;
    letter-spacing: 0;
    margin-top: 16px;
  }

  .calendar-item-container {
    gap: 4px;
    row-gap: 8px;
}

  .calendar-section .title-bar {
    flex-direction: column;
    gap: 16px;
    font-size: 24px;
    justify-content: flex-start;
    align-items: center;
  }

}

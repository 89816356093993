.cardContainer {
  padding: 27px;
  font-weight: var(--FONT_WEIGHT_800);
  gap: 24px;
  border-radius: 12px;
  box-shadow: 0px 8px 16px 0px rgba(17, 17, 17, 0.04);
  color: var(--PRIMARY);
  font-family: var(--Faktum-Test);
}

.cardContainer h1 {
  font-size: var(--FONT48);
  font-weight: var(--FONT_WEIGHT_800);
  letter-spacing: -1px;
  margin-bottom: 0;
}

.cardTitle {
  font-size: var(--FONT18);
  letter-spacing: -1px;
  margin-bottom: 0;
}

.matchHeader {
  color: var(--WHITE);
  font-size: var(--FONT32);
  font-weight: var(--FONT_WEIGHT_800);
  padding-top: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--WHITE);
  letter-spacing: -1px;
  margin-bottom: 20px;
}

.matchCard {
  display: flex;
  min-height: 200px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 0;
  font-weight: var(--FONT_WEIGHT_800);
  flex: 1 0;
  border-radius: 24px;
  background-color: var(--SUBGREEN);
  box-shadow: 0px 8px 16px 0px rgba(17, 17, 17, 0.04);
  color: var(--PRIMARY);
  font-family: var(--Faktum-Test);
  position: relative;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}

.subContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftSubContainer {
  background-color: var(--WHITE);
  display: flex;
  width: 139px;
  height: 34px;
  padding: 6.354px;
  justify-content: center;
  align-items: center;
  gap: 7.943px;
  border-radius: 14.297px;
  margin-bottom: 0;
}

.rightSubContainer {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  background-color: var(--WHITE);
  border-radius: 12px;
  margin-bottom: 0;
}

.matchBody {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;

}

.matchTitle {
  font-size: var(--FONT32);
  line-height: 40px;
  margin-top: 14px;
  margin-bottom: 0px;
  letter-spacing: -2px;
}

.matchRightContainer {
  border-radius: 78.628px;
  background: var(--WHITE);
  display: flex;
  justify-content: center;
  padding: 7.863px;
  font-size: var(--FONT40);
  font-weight: var(--FONT_WEIGHT_800);
}

.matchRightContainer span {
  font-size: var(--FONT24);
}




.file-upload-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--SUBTERTIARY);
}
.download-excel{
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
text-decoration: none;
margin-top: 60px;
color: var(--LIGHTPRIMARY);
font-family: var(--Aeonik-TRIAL);
}

.download-excel span{
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: var(--SUBBLUE);
  color: var(--PRIMARYVARIANT);
  font-family: var(--Aeonik-TRIAL);
}
.file-upload-container-header{
  width: 701px;
  max-height: 736px;
  border-radius: 8px;
  background-color: var(--WHITE);
  position: relative;

}
.file-upload-container-header-title{
  font-weight: var(--FONT_WEIGHT_800);
  font-size: var(--FONT20);
  padding: 30px 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.container-style{
  padding: 0px 48px;
}
.dropzone{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-width: 1px;
    border-radius: 2px;
    border-color: var(--PRIMARY);
    border-style: dashed;
    background-color: #F8F8FF;
    color: #bdbdbd;
    outline: none;
    height: 427px;
    justify-content: center;
    transition: border .24s ease-in-out;
}
.dropzone .main-text{
  color: var(--PRIMARYVARIANT);
  margin-bottom: 5px;
  font-family: var(--Aeonik-TRIAL);
  font-weight: var(--FONT_WEIGHT_400);
}
.dropzone .main-text span{
cursor: pointer;
text-decoration: underline;
text-decoration-color: var(--SUBBLUE);
}
.sub-text{
  color: var(--LIGHTPRIMARY);
  font-family: var(--Aeonik-TRIAL);
  font-weight: var(--FONT_WEIGHT_400);
}

.upload-button{
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--SECONDARY);
  color: var(--PRIMARY);
  border: none;
  padding: 16px 24px;
  margin-top: 30px;
  font-weight: var(--FONT_WEIGHT_800);
  font-size: var(--FONT16);
  margin-bottom: 32px;
  text-transform: uppercase;
  border-radius: 4px;
  cursor:pointer
}

.upload-button:disabled{
  color: var(--BTNDISABLETEXT) !important;
  opacity: 0.5;
  background-color: var(--SECONDARY);
}

@media screen and (max-width: 1023px) {
  .file-upload-container{
    height: calc(100vh - 88px);
  }
}